import { FC, useCallback, useRef, useState } from 'react';
import LoadingModal from '../Modals/LoadingModal';
import { useForm } from 'react-hook-form';
import { RegisterFormData } from '../../models/forms';
import Button from '../Button/Button';
import { registerUser } from '../../api/users-requests';
import { Trans, useTranslation } from 'react-i18next';
import ErrorBox from '../ErrorBox';
import SuccessBox from '../SuccessBox';
import { ButtonColors } from '../../utilities/enum/buttonColors';
import InputField from './Fields/InputField';
import { FormIds } from '../../utilities/enum/formIds';
import { testEmail, testPassword } from '../../utilities/functions';
import CheckboxField from './Fields/CheckboxField';
import { privacyPolicyLink } from '../../resources/constants';
import ReCAPTCHA from 'react-google-recaptcha';
import { verifyRecaptcha } from '../../api/recaptcha';

interface Props {
    onBackToLogin: () => void;
}

const RegisterForm: FC<Props> = ({ onBackToLogin }) => {
    const {
        register,
        handleSubmit,
        trigger,
        watch,
        reset,
        formState: { errors, isValid },
    } = useForm<RegisterFormData>({ mode: 'onBlur', criteriaMode: 'all' });
    const { t } = useTranslation();
    const passwordWatch = watch(FormIds.PASSWORD);

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
    const [registrationCompleted, setRegistrationCompleted] = useState(false);

    const handleRegister = useCallback(
        async (formData: RegisterFormData) => {
            trigger();
            const token = await verifyRecaptcha(recaptchaRef.current as ReCAPTCHA);
            if (isValid && token) {
                setLoading(true);
                try {
                    const registeredUser = await registerUser(formData, token);
                    if (registeredUser && registeredUser.user) {
                        setSuccess(t('login_page.registration_completed'));
                        setRegistrationCompleted(true);
                        reset();
                    } else {
                        setError('generic_registration_error');
                    }
                } catch (error: any) {
                    if (error && error.error) {
                        setError(error.error);
                    } else {
                        setError('generic_registration_error');
                    }
                }
                setLoading(false);
            }
        },
        [trigger, isValid, t],
    );

    const checkValidEmail = useCallback(
        (newText: string) => {
            if (testEmail(newText)) {
                return true;
            } else {
                return t('errors.email_format_error');
            }
        },
        [t],
    );

    const checkValidPassword = useCallback(
        (newText: string) => {
            if (testPassword(newText)) {
                return true;
            } else {
                return t('general.forms.password_format');
            }
        },
        [t],
    );

    const validateConfirmPassword = useCallback(
        (newText: string) => {
            if (newText == passwordWatch) {
                return true;
            } else {
                return t('errors.passwords_not_match');
            }
        },
        [passwordWatch],
    );

    const changeVisiblePassword = useCallback(() => {
        setVisiblePassword(!visiblePassword);
    }, [visiblePassword]);

    const changeVisibleConfirmPassword = useCallback(() => {
        setVisibleConfirmPassword(!visibleConfirmPassword);
    }, [visibleConfirmPassword]);

    return (
        <>
            <form onSubmit={handleSubmit(handleRegister)}>
                <InputField
                    type="name"
                    id={FormIds.NAME}
                    register={register}
                    errors={errors[FormIds.NAME] ?? null}
                    placeholder={t('login_page.name')}
                    label=""
                    isRequired
                />
                <InputField
                    type="email"
                    id={FormIds.EMAIL}
                    register={register}
                    errors={errors[FormIds.EMAIL] ?? null}
                    placeholder={t('login_page.email')}
                    label=""
                    isRequired
                    validate={checkValidEmail}
                />
                <InputField
                    id={FormIds.PASSWORD}
                    register={register}
                    errors={errors[FormIds.PASSWORD] ?? null}
                    placeholder={t('login_page.password')}
                    label=""
                    type={visiblePassword ? 'text' : 'password'}
                    icon={visiblePassword ? 'eye-slash' : 'eye'}
                    onClickIcon={changeVisiblePassword}
                    validate={checkValidPassword}
                    isRequired
                />
                <InputField
                    id={FormIds.CONFIRM_PASSWORD}
                    register={register}
                    errors={errors[FormIds.CONFIRM_PASSWORD] ?? null}
                    placeholder={t('login_page.password_confirm')}
                    label=""
                    type={visibleConfirmPassword ? 'text' : 'password'}
                    icon={visibleConfirmPassword ? 'eye-slash' : 'eye'}
                    onClickIcon={changeVisibleConfirmPassword}
                    isRequired
                    validate={validateConfirmPassword}
                />
                <CheckboxField
                    label={
                        <Trans
                            i18nKey={'login_page.read_privacy'}
                            components={{ privacy: <a href={privacyPolicyLink} target="_blank" /> }}
                        />
                    }
                    register={register}
                    id={FormIds.PRIVACY_ACCEPT}
                    errors={errors[FormIds.PRIVACY_ACCEPT] ?? null}
                    isRequired
                />
                {error ? <ErrorBox message={error} /> : null}
                {success ? <SuccessBox message={success} /> : null}
                {!registrationCompleted ? (
                    <Button
                        isSubmit
                        styleClass={ButtonColors.WHITE_TRANSPARENT}
                        text={t('login_page.signup')}
                        onClick={() => {}}
                    />
                ) : (
                    <Button
                        styleClass={ButtonColors.LINK_WHITE}
                        text={t('login_page.back_to_login')}
                        onClick={onBackToLogin}
                    />
                )}
                <ReCAPTCHA
                    size="invisible"
                    theme="dark"
                    sitekey={process.env.REACT_APP_RECAPTCHA_ID as string}
                    ref={recaptchaRef}
                />
            </form>
            <LoadingModal isVisible={loading} />
        </>
    );
};

export default RegisterForm;
