import Button from '../../../../components/Button/Button';
import FilterStepQuestion from '../../../../components/FilterStepQuestion/FilterStepQuestion';
import MultiFilterSelector from '../../../../components/MultiFilterSelector/MultiFilterSelector';
import { Genre } from '../../../../models/data';
import { ReducerData, Reducers } from '../../../../models/reducers';
import { ContentType } from '../../../../utilities/enum/contentType';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ButtonColors } from '../../../../utilities/enum/buttonColors';

interface Props {
    type: ContentType;
    userGenres: Genre[];
    onNext: (platforms: Genre[]) => void;
    onBack: () => void;
}

const ChooseGenresStep: FC<Props> = ({ type, userGenres, onNext, onBack }) => {
    const { t } = useTranslation();
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const [selectedGenres, setSelectedGenres] = useState<Genre[]>(userGenres);

    return (
        <div className="filterContainer-content_step -genres">
            <FilterStepQuestion number={3} text={t('filter_page.mobile.choose_genres')} />
            <MultiFilterSelector
                title={''}
                contentType={type}
                type={'genre'}
                datas={type === ContentType.MOVIE ? data.movieGenres : data.showGenres}
                selectedDatas={selectedGenres}
                onChange={(array) => {
                    const providers = array as Genre[];
                    setSelectedGenres(providers);
                }}
            />
            <Button
                styleClass={ButtonColors.WHITE_TRANSPARENT}
                text={t('filter_page.mobile.next_step')}
                onClick={() => {
                    onNext(selectedGenres);
                }}
            />
            <Button
                text={t('filter_page.mobile.previous_step')}
                onClick={onBack}
                styleClass={ButtonColors.LINK_OPACITY}
            />
        </div>
    );
};

export default ChooseGenresStep;
