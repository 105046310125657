import { CSSProperties, FC } from 'react';
import Icon from '../Icon/Icon';

import './GreyButton.scss';
import { ContentType } from '../../utilities/enum/contentType';

interface Props {
    onClick: () => void;
    text?: string;
    icon?: string;
    image?: string;
    style?: CSSProperties;
    selected?: boolean;
    type?: ContentType;
}

const GreyButton: FC<Props> = ({ onClick, text, icon, image, style = {}, selected = false, type }) => {
    return (
        <div
            className={`greyButton ${text || image ? '-flex' : null} ${selected ? '-selected' : ''} ${
                type ? (type === ContentType.MOVIE ? '-movie' : '-show') : ''
            }`}
            style={{
                ...style,
            }}
            onClick={onClick}
        >
            {icon ? <Icon name={icon} size={24} color="#fff" /> : null}
            {image || text ? (
                <>
                    {image ? <img src={image} alt={text ?? ''} /> : null}
                    {text ? <span>{text}</span> : null}
                </>
            ) : null}
        </div>
    );
};

export default GreyButton;
