import Button from '../../components/Button/Button';
import Modal from '@mui/material/Modal';
import { FC } from 'react';
import { ButtonColors } from '../../utilities/enum/buttonColors';

interface Props {
    isVisible: boolean;
    answer: string;
    confirmText: string;
    cancelText: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const ConfirmModal: FC<Props> = ({ isVisible, answer, confirmText, cancelText, onCancel, onConfirm }) => {
    return (
        <Modal open={isVisible} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div className="modalBox cofirmModal">
                <h2 className="modalBox-title">{answer}</h2>
                <div className="modalBox-buttonsRow">
                    <Button onClick={onCancel} text={cancelText} styleClass={ButtonColors.LINK_OPACITY} />
                    <Button onClick={onConfirm} text={confirmText} />
                    {/* <p className="modalBox-buttonsRow_confirm" onClick={onConfirm}>
                        {confirmText}
                    </p> */}
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
