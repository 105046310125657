import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import './JustWatchReference.scss';

const JustWatchReference: FC = () => {
    const { t } = useTranslation();

    const openLink = useCallback(() => {
        window.open('https://justwatch.com', '_blank');
    }, []);

    return (
        <div className="justWatchReferenceContainer">
            <span>{t('result_page.common.powered_by')}</span>
            <img
                src={require('../../resources/images/JustWatch-logo.png')}
                alt={'JUST WATCH'}
                onError={({ currentTarget }) => (currentTarget.src = '')}
                onClick={openLink}
            />
        </div>
    );
};

export default JustWatchReference;
