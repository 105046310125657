import { FC, useCallback, useRef, useState } from 'react';
import LoadingModal from '../Modals/LoadingModal';
import { useForm } from 'react-hook-form';
import { ResetPasswordFormData, SendResetPasswordFormData } from '../../models/forms';
import Button from '../Button/Button';
import { resetPassowrd, sendResetPasswordEmail } from '../../api/users-requests';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ErrorBox from '../ErrorBox';
import SuccessBox from '../SuccessBox';
import { ButtonColors } from '../../utilities/enum/buttonColors';
import InputField from './Fields/InputField';
import { FormIds } from '../../utilities/enum/formIds';
import { testEmail, testPassword } from '../../utilities/functions';
import { verifyRecaptcha } from '../../api/recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import Paragraph from '../Paragraph';

interface Props {
    token: string;
}

const ResetPasswordForm: FC<Props> = ({ token }) => {
    const {
        register,
        handleSubmit,
        trigger,
        reset,
        watch,
        formState: { errors, isValid },
    } = useForm<ResetPasswordFormData>({ mode: 'onBlur', criteriaMode: 'all' });
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const passwordWatch = watch(FormIds.PASSWORD);

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const navigate = useNavigate();

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [visiblePassword, setVisiblePassword] = useState(false);
    const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);

    const handleSendResetMail = useCallback(
        async (formData: ResetPasswordFormData) => {
            trigger();
            const recaptchaToken = await verifyRecaptcha(recaptchaRef.current as ReCAPTCHA);
            if (isValid && token && recaptchaToken) {
                setError('');
                setLoading(true);
                try {
                    await resetPassowrd({ resetPasswordToken: token, password: formData.password }, recaptchaToken);
                    reset();
                    setSuccess(t('reset_password_page.reset_password_success'));
                } catch (error: any) {
                    if (error.error == 'email_not_found') {
                        setSuccess(t('login_page.reset_password_email_sent'));
                    } else {
                        setError(t(`errors.${error.error}`) ?? t('errors.generic_reset_password_error'));
                    }
                }
                setLoading(false);
            } else {
                setError(t('errors.fill_login_form_error'));
            }
        },
        [dispatch, trigger, isValid, t],
    );

    const checkValidPassword = useCallback(
        (newText: string) => {
            if (testPassword(newText)) {
                return true;
            } else {
                return t('general.forms.password_format');
            }
        },
        [t],
    );

    const handleBackToLogin = useCallback(() => {
        if (success) {
            navigate(ROUTES.LOGIN_PAGE);
        }
    }, [navigate, success]);

    const validateConfirmPassword = useCallback(
        (newText: string) => {
            if (newText == passwordWatch) {
                return true;
            } else {
                return t('errors.passwords_not_match');
            }
        },
        [passwordWatch],
    );

    const changeVisiblePassword = useCallback(() => {
        setVisiblePassword(!visiblePassword);
    }, [visiblePassword]);

    const changeVisibleConfirmPassword = useCallback(() => {
        setVisibleConfirmPassword(!visibleConfirmPassword);
    }, [visibleConfirmPassword]);

    return (
        <>
            <form onSubmit={handleSubmit(handleSendResetMail)}>
                <InputField
                    id={FormIds.PASSWORD}
                    register={register}
                    errors={errors[FormIds.PASSWORD] ?? null}
                    placeholder={t('login_page.password')}
                    label=""
                    type={visiblePassword ? 'text' : 'password'}
                    icon={visiblePassword ? 'eye-slash' : 'eye'}
                    onClickIcon={changeVisiblePassword}
                    validate={checkValidPassword}
                    isRequired
                />
                <InputField
                    id={FormIds.CONFIRM_PASSWORD}
                    register={register}
                    errors={errors[FormIds.CONFIRM_PASSWORD] ?? null}
                    placeholder={t('login_page.password_confirm')}
                    label=""
                    type={visibleConfirmPassword ? 'text' : 'password'}
                    icon={visibleConfirmPassword ? 'eye-slash' : 'eye'}
                    onClickIcon={changeVisibleConfirmPassword}
                    isRequired
                    validate={validateConfirmPassword}
                />
                {error ? <ErrorBox message={error} /> : null}
                {success ? (
                    <>
                        <SuccessBox message={success} />
                        <Paragraph>
                            <p onClick={handleBackToLogin} style={{ color: '#fff', cursor: 'pointer' }}>
                                ← {t('reset_password_page.back_to_login')}
                            </p>
                        </Paragraph>
                    </>
                ) : (
                    <Button
                        isSubmit
                        styleClass={ButtonColors.WHITE_TRANSPARENT}
                        text={t('login_page.send_email')}
                        onClick={() => {}}
                    />
                )}

                <ReCAPTCHA
                    size="invisible"
                    theme="dark"
                    sitekey={process.env.REACT_APP_RECAPTCHA_ID as string}
                    ref={recaptchaRef}
                />
            </form>
            <LoadingModal isVisible={loading} />
        </>
    );
};

export default ResetPasswordForm;
