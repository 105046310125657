import { FC, ReactNode } from 'react';

import { FieldError, FieldErrorsImpl, Merge, UseFormRegister } from 'react-hook-form';
import { LoginFormData, RegisterFormData } from '../../../models/forms';
import { FormIds } from '../../../utilities/enum/formIds';
import { useTranslation } from 'react-i18next';

interface Props {
    label: string | ReactNode;
    // ts-ignore
    register: UseFormRegister<LoginFormData | RegisterFormData | any>;
    errors: FieldError | Merge<FieldError, FieldErrorsImpl<LoginFormData | RegisterFormData>> | null;
    id: FormIds;
    isRequired?: boolean;
    requiredMessage?: string;
}

const CheckboxField: FC<Props> = ({
    label,
    register,
    errors,
    id,
    isRequired = false,
    requiredMessage = 'general.forms.field_required',
}) => {
    const { t } = useTranslation();

    return (
        <div className="formField -checkbox">
            <div className="formField_checkboxRow">
                <input type="checkbox" {...register(id, { required: isRequired ? t(requiredMessage) : false })} />
                {label && <p className="sag-copy -textXL">{label}</p>}
            </div>
            {errors && errors.message && <p className="formField_error">{errors.message}</p>}
        </div>
    );
};

export default CheckboxField;
