import { FixedBackgroundColors } from '../../utilities/enum/fixedBackgroundColors';
import { FC, useMemo } from 'react';
import './FixedBackground.scss';

interface Props {
    color?: FixedBackgroundColors;
}

const FixedBackground: FC<Props> = ({ color = FixedBackgroundColors.DARK_GRADIENT }) => {
    const colorClass = useMemo(() => {
        switch (color) {
            case FixedBackgroundColors.DARK_GRADIENT:
                return 'dark';
            case FixedBackgroundColors.MOVIE_GRADIENT:
                return 'movie';
            case FixedBackgroundColors.TV_SHOW_GRADIENT:
                return 'tvShow';
            default:
                return 'dark';
        }
    }, [color]);

    return <div className={`fixedBackground -${colorClass}`} />;
};

export default FixedBackground;
