import React from 'react';
import { Genre, Provider } from '../../models/data';
import { ContentType } from '../../utilities/enum/contentType';
import Chip from '../../components/Chip/Chip';
import GreyButton from '../../components/GreyButton/GreyButton';

import './MultiFilterSelector.scss';

interface Props {
    title?: string;
    datas: Genre[] | Provider[];
    contentType: ContentType;
    type: 'genre' | 'provider' | 'year';
    selectedDatas: Genre[] | Provider[];
    onChange: (selected: Genre[] | Provider[]) => void;
}

const MultiFilterSelector: React.FC<Props> = ({ title, datas, contentType, type, selectedDatas, onChange }) => {
    const renderList = () => {
        switch (type) {
            case 'genre':
                return (
                    <div className="multiFilterContainer-container--multiSelection">
                        {(datas as Genre[]).map((g) => {
                            const isSelected =
                                (selectedDatas as Genre[]).find((genre) => genre.id === g.id) !== undefined;
                            return (
                                <Chip
                                    key={`genre${g.id}`}
                                    name={g.name}
                                    selected={isSelected}
                                    type={contentType}
                                    onClick={() => {
                                        if ((selectedDatas as Genre[]).find((genre) => genre.id === g.id)) {
                                            onChange((selectedDatas as Genre[]).filter((genre) => genre.id !== g.id));
                                        } else {
                                            onChange([...(selectedDatas as Genre[]), g]);
                                        }
                                    }}
                                />
                            );
                        })}
                    </div>
                );
            case 'provider':
                return (
                    <div className="multiFilterContainer-container--gridSelection">
                        {(datas as Provider[]).map((provider) => {
                            const isSelected =
                                (selectedDatas as Provider[]).find((p) => p.id === provider.id) !== undefined;
                            return (
                                <GreyButton
                                    onClick={() => {
                                        if ((selectedDatas as Provider[]).find((p) => p.id === provider.id)) {
                                            onChange((selectedDatas as Provider[]).filter((p) => p.id !== provider.id));
                                        } else {
                                            onChange([...(selectedDatas as Provider[]), provider]);
                                        }
                                    }}
                                    image={`${provider.logo}`}
                                    key={`multiFilterSelectorProvider${provider.id}`}
                                    text={provider.name}
                                    selected={isSelected}
                                    type={contentType}
                                />
                            );
                        })}
                    </div>
                );
        }
    };

    return (
        <div className="multiFilterContainer">
            {title ? <h3>{title}</h3> : null}
            <div className="multiFilterContainer-container">{renderList()}</div>
        </div>
    );
};

export default MultiFilterSelector;
