import { FC } from 'react';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import TypeSelectorButton from '../../components/TypeSelectorButton';
import useWindowDimensions from '../../utilities/hooks';
import { COLORS } from '../../resources/constants';
import { ContentType } from '../../utilities/enum/contentType';

import './Modals.scss';

interface Props {
    isVisible: boolean;
    onContentSelect: (type: ContentType) => void;
}

const ChooseTypeModal: FC<Props> = ({ isVisible, onContentSelect }) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    return (
        <Modal open={isVisible} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div className="modalBox cofirmModal">
                <h2 className="modalBox-title">{t('feeling_page.mobile.choose_type')}</h2>
                <div className="modalBox-typeRow">
                    <TypeSelectorButton
                        color={COLORS.purple}
                        text={t('feeling_page.mobile.movie')}
                        size={width * 0.2}
                        onClick={() => {
                            onContentSelect(ContentType.MOVIE);
                        }}
                    />
                    <TypeSelectorButton
                        color={COLORS.yellow}
                        text={t('feeling_page.mobile.tv_show')}
                        size={width * 0.2}
                        onClick={() => {
                            onContentSelect(ContentType.TV_SHOW);
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ChooseTypeModal;
