import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './store/reducers';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './i18n';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './resources/constants';

// if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
//     PiwikPro.initialize('f2576cca-8aba-492f-a169-df589f940d98', 'https://whatwatch.containers.piwik.pro');
// }

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container as HTMLElement);

initializeApp(firebaseConfig);

root.render(
    <GoogleOAuthProvider clientId="485151355487-nderbebsn478hba6o3sgreiciio125if.apps.googleusercontent.com">
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </GoogleOAuthProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
