import React from 'react';

interface Props {
    color: string;
    text?: string;
    size: number;
    onClick: () => void;
}

const TypeSelectorButton: React.FC<Props> = ({ color, text = '', size, onClick }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }}
            onClick={() => {
                onClick();
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: size,
                    height: size,
                    borderRadius: size,
                    border: `${size * 0.1}px solid ${color}`,
                    cursor: 'pointer',
                }}
            >
                <div
                    style={{
                        width: size * 0.6,
                        height: size * 0.6,
                        borderRadius: size,
                        backgroundColor: color,
                        cursor: 'pointer',
                    }}
                />
            </div>
            {text ? <p style={{ color: color, fontSize: size * 0.3, margin: 0 }}>{text}</p> : null}
        </div>
    );
};

export default TypeSelectorButton;
