import { Snackbar } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface Props {
    onClose: () => void;
    type: 'error' | 'success';
    message: string;
}

const Toast: FC<Props> = ({ onClose, type, message }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (message) {
            setOpen(true);
        }
    }, [message]);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        onClose();
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={1000}
            onClose={handleClose}
            message={message}
            sx={{
                '& .MuiSnackbarContent-root': {
                    color: '#fff',
                    backgroundColor: type === 'success' ? 'rgba(243, 22, 180, 0.9)' : 'rgba(252, 35, 35, 0.9)',
                    borderColor: type === 'success' ? 'rgb(243, 22, 180)' : 'rgb(252, 35, 35)',
                    textAlign: 'center',
                    width: 'auto',
                    minWidth: 'auto',
                },
            }}
        />
    );
};

export default Toast;
