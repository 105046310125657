import { GRAPHIC_ACTIONS } from '../../store/actions/graphic';
import { ReduxActionData, ReducerGraphic } from '../../models/reducers';

const defaultValue: ReducerGraphic = {
    selectedWatch: null,
    isLoading: false,
    filtering: false,
};

const graphicState = (state: ReducerGraphic = defaultValue, action: ReduxActionData<any>): ReducerGraphic => {
    switch (action.type) {
        case GRAPHIC_ACTIONS.SET_SELECTED_WATCH:
            return {
                ...state,
                selectedWatch: action.payload,
            };
        case GRAPHIC_ACTIONS.SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case GRAPHIC_ACTIONS.SET_FILTERING:
            return {
                ...state,
                filtering: action.payload,
            };
        default:
            return state;
    }
};

export default graphicState;
