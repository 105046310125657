import { Genre, Provider } from '../models/data';
import { privacyPolicyLink } from '../resources/constants';

export const getTimeString: (time: number) => string = (time: number) => {
    const hours = Math.floor(time / 60);
    const remainingMinutes = time % 60;
    return `${hours} h ${remainingMinutes} min`;
};

export const passwordRegEx =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#$%^&*_\-+=|?])[a-zA-Z0-9.!@#$%^&*_\-+=|?]{8,}$/;

export const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const testEmail = (email: string) => {
    if (emailRegEx.test(email)) {
        return true;
    }
    return false;
};

export const testPassword = (password: string) => {
    if (passwordRegEx.test(password)) {
        return true;
    }
    return false;
};

export const getUserProvidersArray = (
    userPlatforms: number[],
    movieProviders: Provider[],
    showProvider: Provider[],
): Provider[] => {
    const providers: Provider[] = [];
    userPlatforms.forEach((platform) => {
        let foundInPlatforms = movieProviders.find((provider) => provider.id === platform);
        if (!foundInPlatforms) {
            foundInPlatforms = showProvider.find((provider) => provider.id === platform);
        }
        if (foundInPlatforms) {
            providers.push(foundInPlatforms);
        }
    });
    return providers;
};

export const getUserGenresArray = (userGenres: number[], movieGenres: Genre[], showGenres: Genre[]): Genre[] => {
    const genres: Genre[] = [];
    userGenres.forEach((genre) => {
        let foundInGenres = movieGenres.find((movieGenre) => movieGenre.id === genre);
        if (!foundInGenres) {
            foundInGenres = showGenres.find((showGenre) => showGenre.id === genre);
        }
        if (foundInGenres) {
            genres.push(foundInGenres);
        }
    });
    return genres;
};

export const openPrivacyPolicy = () => {
    window.open(privacyPolicyLink, '_blank');
};

export const getOnLineStatus = () => {
    return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true;
};
