import { FC, useCallback, useEffect, useState } from 'react';
import { BrowserView, MobileOnlyView, TabletView, isSafari } from 'react-device-detect';

interface Props {
    desktopComponent: JSX.Element;
    mobileComponent: JSX.Element;
}

const ViewportSwitchComponent: FC<Props> = ({ desktopComponent, mobileComponent }) => {
    const [orientation, setOrientation] = useState<string>('portrait');

    const changeOrientation = useCallback(() => {
        if (window.innerHeight > window.innerWidth) {
            setOrientation('portrait');
        } else {
            setOrientation('landscape');
        }
    }, []);

    useEffect(() => {
        if (window.screen.orientation.type.includes('landscape')) {
            setOrientation('landscape');
        } else {
            setOrientation('portrait');
        }
        window.addEventListener('orientationchange', changeOrientation);

        return () => {
            window.removeEventListener('orientationchange', changeOrientation);
        };
    }, [changeOrientation]);

    if (isSafari) {
        return window.innerWidth > 750 ? desktopComponent : mobileComponent;
    }

    return (
        <>
            <BrowserView>{desktopComponent}</BrowserView>
            <MobileOnlyView>{mobileComponent}</MobileOnlyView>
            <TabletView>{orientation === 'landscape' ? desktopComponent : mobileComponent}</TabletView>
        </>
    );
};

export default ViewportSwitchComponent;
