import Button from '../../../../components/Button/Button';
import FilterStepQuestion from '../../../../components/FilterStepQuestion/FilterStepQuestion';
import MultiFilterSelector from '../../../../components/MultiFilterSelector/MultiFilterSelector';
import { Provider } from '../../../../models/data';
import { ReducerData, Reducers } from '../../../../models/reducers';
import { ContentType } from '../../../../utilities/enum/contentType';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ButtonColors } from '../../../../utilities/enum/buttonColors';

interface Props {
    type: ContentType;
    userProviders: Provider[];
    onNext: (platforms: Provider[]) => void;
    onBack: () => void;
}

const ChoosePlatformsStep: FC<Props> = ({ type, userProviders, onNext, onBack }) => {
    const { t } = useTranslation();
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const [selectedProviders, setSelectedProviders] = useState<Provider[]>(userProviders);

    return (
        <div className="filterContainer-content_step -platforms">
            <FilterStepQuestion number={2} text={t('filter_page.mobile.choose_platforms')} />
            <MultiFilterSelector
                title={''}
                contentType={type}
                type={'provider'}
                datas={type === ContentType.MOVIE ? data.movieProvider : data.showProvider}
                selectedDatas={selectedProviders}
                onChange={(array) => {
                    const providers = array as Provider[];
                    setSelectedProviders(providers);
                }}
            />
            <Button
                styleClass={ButtonColors.WHITE_TRANSPARENT}
                text={t('filter_page.mobile.next_step')}
                onClick={() => {
                    onNext(selectedProviders);
                }}
            />
            <Button
                text={t('filter_page.mobile.previous_step')}
                onClick={onBack}
                styleClass={ButtonColors.LINK_OPACITY}
            />
        </div>
    );
};

export default ChoosePlatformsStep;
