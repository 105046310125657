// Import here your languages
import en from './locales/en.json';
import it from './locales/it.json';

// Set here you favourite default language
export const defaultLanguage = 'it';

// Export here your language files import
export const languagesResources: Record<string, any> = {
    en: { translation: en },
    it: { translation: it },
};

export const availableLanguages = ['en', 'it'];

export const languagesCodeMap: Record<string, string> = {
    it: 'it-IT',
    en: 'en-US'
}
