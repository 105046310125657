import { combineReducers } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {thunk} from 'redux-thunk';
import data from './data'
import graphic from './graphic'
import user from './user'
import { configureStore } from '@reduxjs/toolkit'

const config = {
    key: 'root',
    storage: storage,
    blacklist: ['data', "graphic"],
    whitelist: ['user']
}

// Group reducers
const rootReducer = combineReducers({
    data,
    graphic,
    user
})

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(config, rootReducer)

// const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)
// const store =  createStoreWithMiddleware(persistedReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(thunk),
    devTools:
        process.env.NODE_ENV === 'development' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__({ name: 'PU' }),
});

const persistor = persistStore(store)

export { persistor, store }