import { FB_API_VERSION, FB_DATASET_ID, FB_TOKEN_API_CONVERSION } from '../resources/constants';
import { ContentType } from '../utilities/enum/contentType';
import axios from 'axios';
import { getTime } from 'date-fns';

export const postFBViewContentEvent = async (
    contentType: ContentType,
    searchType: 'random' | 'filtered' | 'feeling' | 'specific',
    isLoggedIn: boolean,
    email: string | null,
) => {
    const currentTime = Math.floor(getTime(new Date()) / 1000);
    const payload = {
        data: [
            {
                event_name: 'ViewContent',
                event_time: currentTime,
                action_source: 'website',
                custom_data: {
                    contentType: contentType,
                    searchType: searchType,
                    loggedIn: isLoggedIn,
                    email: email,
                },
            },
        ],
    };
    await axios.post(
        `https://graph.facebook.com/${FB_API_VERSION}/${FB_DATASET_ID}/events?access_token=${FB_TOKEN_API_CONVERSION}`,
        payload,
    );
};
