export enum ButtonColors {
    GRADIENT = 'gradient',
    REVERT_GRADIENT = 'revertGradient',
    SOLID_TV_SHOW = 'solidYellow',
    SOLID_MOVIE = 'solidPurple',
    LINK_TV_SHOW = 'linkYellow',
    LINK_MOVIE = 'linkPurple',
    LINK_DELETE = 'linkDelete',
    LINK_OPACITY = 'linkOpacity',
    LINK_WHITE = 'linkWhite',
    WHITE_TRANSPARENT = 'whiteTransparent',
}
