import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import { ReducerUser, Reducers } from '../../models/reducers';
import { parse } from 'qs';
import { testPassword } from '../../utilities/functions';
import { resetPassowrd } from '../../api/users-requests';
import { ResultType } from '../../models/user';
import ViewportSwitchComponent from '../../components/ViewportSwitchComponent';
import ResetPasswordPageDesktop from './Desktop/ResetPasswordPageDesktop';

export interface Password {
    password: string;
    confirmPassword: string;
}

export interface ResetPassword {
    resetPasswordToken: string;
    password: string;
}

const ResetPasswordPage: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const [token, setToken] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (user.user) {
            navigate(ROUTES.ACCOUNT_PAGE);
        }

        const params = parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        if (params && params.token) {
            setToken(params.token as string);
        } else {
            navigate(ROUTES.LOGIN_PAGE);
        }
    }, [navigate, user.user]);

    return (
        <ViewportSwitchComponent
            desktopComponent={<ResetPasswordPageDesktop token={token} />}
            mobileComponent={<ResetPasswordPageDesktop token={token} />}
        />
    );
};

export default ResetPasswordPage;
