import React from 'react';
import Modal from '@mui/material/Modal';
import Loader from '../../components/Loader/Loader';

import './Modals.scss';
interface Props {
    isVisible: boolean;
}

const LoadingModal: React.FC<Props> = ({ isVisible }) => {
    return (
        <Modal open={isVisible} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader />
            </div>
        </Modal>
    );
};

export default LoadingModal;
