import React, { useCallback, useMemo, useState } from 'react';
import Button from '../../../components/Button/Button';
import Title from '../../../components/Title';
import { openPrivacyPolicy, testEmail, testPassword } from '../../../utilities/functions';
import { registerUser } from '../../../api/users-requests';
import SuccessBox from '../../../components/SuccessBox';
import ErrorBox from '../../../components/ErrorBox';
import LoadingModal from '../../../components/Modals/LoadingModal';
import { useTranslation } from 'react-i18next';
import { ButtonColors } from '../../../utilities/enum/buttonColors';
import RegisterForm from '../../../components/Forms/RegisterForm';

export interface RegisterData {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
}

interface Props {
    onLogin: () => void;
}

const initialRegisterData = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
};

const RegisterPhase: React.FC<Props> = ({ onLogin }) => {
    const { t } = useTranslation();

    return (
        <div className="loginContainer-content_formColumn__columnContent">
            <Title text="Registrati a WhatWatch" align="left" weight="bold" />
            <RegisterForm onBackToLogin={onLogin} />

            <Title text={t('login_page.have_an_account')} align="left" weight="bold" />
            <Button styleClass={ButtonColors.WHITE_TRANSPARENT} text={t('login_page.signin_now')} onClick={onLogin} />
        </div>
    );
};

export default RegisterPhase;
