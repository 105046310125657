import { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react';
import { ReducerData, Reducers } from '../../models/reducers';
import { useSelector } from 'react-redux';
import './ZoomBackdropsBackground.scss';

const ZoomBackdropsBackground: FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isLeftToRight, setIsLeftToRight] = useState(true);
    const [isFading, setIsFading] = useState(false);
    const [loadedImages, setLoadedImages] = useState<string[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (data.popularBackdrops.length === 0) return;

        const imageInterval = setInterval(() => {
            setIsFading(true);
            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.popularBackdrops.length);
                setIsLeftToRight((prevState) => !prevState);
                setIsFading(false);
            }, 500);
        }, 6000);

        return () => clearInterval(imageInterval);
    }, [data.popularBackdrops]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const imgSrc = entry.target.getAttribute('data-src');
                    if (imgSrc) {
                        setLoadedImages((prev) => [...prev, imgSrc]);
                        (entry.target as HTMLImageElement).src = imgSrc;
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 },
        );

        const imagesToLoad = containerRef.current?.querySelectorAll('img[data-src]');
        imagesToLoad?.forEach((img) => observer.observe(img));

        return () => observer.disconnect();
    }, [data.popularBackdrops, containerRef]);

    const backgroundStyle: CSSProperties = useMemo(() => {
        return {
            backgroundImage: `url(${loadedImages[currentImageIndex]})`,
            opacity: isFading ? 0 : 0.4,
        };
    }, [loadedImages, currentImageIndex, isFading]);

    return (
        <div ref={containerRef}>
            <div
                className={`zoomBackdropBackground ${isLeftToRight ? '-leftToRight' : '-rightToLeft'}`}
                style={backgroundStyle}
            />
            {/* for lazy loading */}
            <div style={{ display: 'none' }}>
                {data.popularBackdrops.map((image, index) => (
                    <img key={index} data-src={image} alt={`Lazy Loaded ${index}`} />
                ))}
            </div>
        </div>
    );
};

export default ZoomBackdropsBackground;
