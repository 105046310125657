import ReCAPTCHA from 'react-google-recaptcha';

export const verifyRecaptcha = async (recaptcha: ReCAPTCHA) => {
    const token = await recaptcha.executeAsync();
    if (token && typeof token == 'string') {
        return token;
    } else {
        return null;
    }
};
