import React from 'react';
import ViewportSwitchComponent from '../../components/ViewportSwitchComponent';
import HeaderDesktop from './Desktop/HeaderDesktop';
import HeaderMobile from './Mobile/HeaderMobile';
import { ContentType } from '../../utilities/enum/contentType';

interface Props {
    isInDetail: boolean;
    hideLogo?: boolean;
    type?: ContentType | string;
    isAccountPage?: boolean;
    isResetPasswordPage?: boolean;
    isFilterPage?: boolean;
    hideOtherSearchButtons?: boolean;
    otherFilmClick?: () => void;
    otherShowClick?: () => void;
    closeAccount?: () => void;
    tabs?: JSX.Element[];
}

const Header: React.FC<Props> = (props) => {
    return (
        <ViewportSwitchComponent
            desktopComponent={<HeaderDesktop {...props} />}
            mobileComponent={<HeaderMobile {...props} />}
        />
    );
};

export default Header;
