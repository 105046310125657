import React, { CSSProperties } from 'react';
import Icon from '../Icon/Icon';
import './ButtonIcon.scss';

interface Props {
    icon: string;
    size: number;
    onClick: (event: HTMLDivElement) => void;
    tooltip?: string;
    style?: CSSProperties;
    isGradient?: boolean;
}

const ButtonIcon: React.FC<Props> = ({ icon, style, size, onClick, tooltip = '', isGradient }) => {
    const handleClick = (event: React.MouseEvent) => {
        if (event.currentTarget) {
            onClick(event.currentTarget as HTMLDivElement);
        } else {
            onClick(document.createElement('div'));
        }
    };

    return (
        <div
            className={`buttonIcon tooltip ${isGradient ? 'gradientBG' : ''}`}
            style={{
                width: size,
                height: size,
                borderRadius: size,
                ...style,
            }}
            onClick={handleClick}
        >
            <Icon name={icon} size={size * 0.6} color="#fff" style={{ marginTop: icon === 'remove' ? -2 : 0 }} />
            {tooltip ? <p className="tooltipText">{tooltip}</p> : null}
        </div>
    );
};

export default ButtonIcon;
