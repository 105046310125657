import { FC, useCallback, useState } from 'react';
import ChangeType from '../../../../components/ChangeType/ChangeType';
import LoadingModal from '../../../../components/Modals/LoadingModal';
import Toast from '../../../../components/Toast';
import ContentsGridComponent from '../../../../components/ContentsGridComponent/ContentsGridComponent';
import Loader from '../../../../components/Loader/Loader';
import { ContentType } from '../../../../utilities/enum/contentType';

interface Props {
    contentType: 'watched' | 'saved';
}

const ContentListTab: FC<Props> = ({ contentType }) => {
    const [selectedType, setSelectedType] = useState<ContentType>(ContentType.MOVIE);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadMore, setLoadMore] = useState(false);

    const handleCloseToast = useCallback(() => {
        setError('');
        setSuccess('');
    }, []);

    return (
        <div className="accountContainer-tabRendered_watchListContainer">
            <ChangeType selectedType={selectedType} onChange={(type) => setSelectedType(type)} />
            <div className="accountContainer-tabRendered_watchListContainer-listContainer">
                <ContentsGridComponent
                    type={selectedType}
                    list={contentType}
                    loadMore={loadMore}
                    onSuccess={setSuccess}
                    onError={setError}
                    onLoading={setLoading}
                    onLoadMore={setLoadMore}
                />
                {loadMore ? <Loader onlyLoader={true} height="40px" /> : null}
            </div>
            <LoadingModal isVisible={loading} />
            <Toast message={success || error} onClose={handleCloseToast} type={success ? 'success' : 'error'} />
        </div>
    );
};

export default ContentListTab;
