import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Header from '../../../components/Header/Header';
import ChangeType from '../../../components/ChangeType/ChangeType';
import MultiFilterSelector from '../../../components/MultiFilterSelector/MultiFilterSelector';
import { Filter, Genre, Provider } from '../../../models/data';
import { initialFilter } from '../FilterPage';
import { ReducerData, Reducers } from '../../../models/reducers';
import { useSelector } from 'react-redux';
import YearRangeSelector from '../../../components/YearRangeSelector/YearRangeSelector';
import { format, getYear } from 'date-fns';
import Button from '../../../components/Button/Button';
import { Popover } from '@mui/material';
import LoadingModal from '../../../components/Modals/LoadingModal';
import Toast from '../../../components/Toast';
import { User } from '../../../models/user';
import { getUserGenresArray, getUserProvidersArray } from '../../../utilities/functions';
import { useTranslation } from 'react-i18next';
import { ContentType } from '../../../utilities/enum/contentType';

import './FilterPageDesktop.scss';
import { ButtonColors } from '../../../utilities/enum/buttonColors';
import ZoomBackdropsBackground from '../../../components/ZoomBackdropsBackground/ZoomBackdropsBackground';
import FixedBackground from '../../../components/FixedBackground/FixedBackground';
import { FixedBackgroundColors } from '../../../utilities/enum/fixedBackgroundColors';

interface Props {
    user: User | null;
    success: string;
    error: string;
    loading: boolean;
    onGoBack: () => void;
    onSearch: (filter: Filter, type: ContentType) => void;
    onSetPreferences: (filter: Filter) => void;
    onCloseToast: () => void;
    onGoToLogin: () => void;
}

const FilterPageDesktop: FC<Props> = ({
    user,
    success,
    error,
    loading,
    onGoBack,
    onSearch,
    onSetPreferences,
    onCloseToast,
    onGoToLogin,
}) => {
    const { t } = useTranslation();
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const [selectedType, setSelectedType] = useState<ContentType>(ContentType.MOVIE);
    const [filter, setFilter] = useState<Filter>(initialFilter);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const openMessagePopover = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleSetPreference = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            if (!user) {
                openMessagePopover(event);
            } else {
                onSetPreferences(filter);
            }
        },
        [user, openMessagePopover, onSetPreferences, filter],
    );

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const convertPlatformsInProviders = useMemo(() => {
        if (user && user.platforms) {
            const providers = getUserProvidersArray(user.platforms, data.movieProvider, data.showProvider);
            return providers;
        } else {
            return [];
        }
    }, [data.movieProvider, data.showProvider, user]);

    const startFilter = useMemo(() => {
        if (user) {
            const genres = getUserGenresArray(user.genres, data.movieGenres, data.showGenres);
            return {
                ...initialFilter,
                genres: genres,
                providers: convertPlatformsInProviders,
            };
        } else {
            return initialFilter;
        }
    }, [convertPlatformsInProviders, data.movieGenres, data.showGenres, user]);

    useEffect(() => {
        setFilter(startFilter);
    }, [startFilter]);

    return (
        <div className="filterContainer">
            <FixedBackground
                color={
                    selectedType == ContentType.MOVIE
                        ? FixedBackgroundColors.MOVIE_GRADIENT
                        : FixedBackgroundColors.TV_SHOW_GRADIENT
                }
            />
            <Header hideLogo={true} isInDetail={false} />
            <ChangeType
                selectedType={selectedType}
                onChange={(type) => setSelectedType(type)}
                showClose
                onCloseButton={onGoBack}
            />
            <div className="filterContainer-filterSelection">
                <div style={{ flex: 2.5 }}>
                    <MultiFilterSelector
                        title={t('filter_page.desktop.genres')}
                        contentType={selectedType}
                        type={'genre'}
                        datas={selectedType === ContentType.MOVIE ? data.movieGenres : data.showGenres}
                        selectedDatas={filter.genres}
                        onChange={(d) => {
                            const datas = d as Genre[];
                            setFilter({
                                ...filter,
                                genres: datas,
                            });
                        }}
                    />
                </div>
                <div style={{ flex: 2.5 }}>
                    <MultiFilterSelector
                        title={t('filter_page.desktop.platforms')}
                        contentType={selectedType}
                        type={'provider'}
                        datas={selectedType === ContentType.MOVIE ? data.movieProvider : data.showProvider}
                        selectedDatas={filter.providers}
                        onChange={(d) => {
                            const datas = d as Provider[];
                            setFilter({
                                ...filter,
                                providers: datas,
                            });
                        }}
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <YearRangeSelector
                        selectedRange={[getYear(new Date(filter.startDate)), getYear(new Date(filter.endDate))]}
                        type={selectedType}
                        onChange={(values) => {
                            if (values.length > 1) {
                                const end =
                                    values[1] < getYear(new Date())
                                        ? `${values[1]}-12-31`
                                        : format(new Date(), 'yyyy-MM-dd');
                                setFilter({
                                    ...filter,
                                    startDate: `${values[0]}-01-01`,
                                    endDate: end,
                                });
                            }
                        }}
                    />
                </div>
            </div>
            <div className="filterContainer-buttonRow">
                <Button
                    styleClass={ButtonColors.WHITE_TRANSPARENT}
                    text={
                        selectedType === ContentType.MOVIE
                            ? t('filter_page.desktop.search_movie')
                            : t('filter_page.desktop.search_tv_show')
                    }
                    onClick={() => onSearch(filter, selectedType)}
                />
                <>
                    <Button
                        text={t('filter_page.desktop.set_preference')}
                        disabled={JSON.stringify(startFilter) === JSON.stringify(filter)}
                        onClick={handleSetPreference}
                        styleClass={ButtonColors.WHITE_TRANSPARENT}
                    />
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        sx={{
                            '& .MuiPaper-root': {
                                background: 'rgba(255, 255, 255, 0.15)',
                            },
                        }}
                    >
                        <div className="filterContainer-buttonRow__popover">
                            <p onClick={onGoToLogin}>{t('filter_page.desktop.login')}</p>
                        </div>
                    </Popover>
                </>
            </div>
            <LoadingModal isVisible={loading} />
            <Toast message={success || error} onClose={onCloseToast} type={success ? 'success' : 'error'} />
        </div>
    );
};

export default FilterPageDesktop;
