import { FC } from 'react';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';

interface Props {
    isVisible: boolean;
}

const OfflineModal: FC<Props> = ({ isVisible }) => {
    const { t } = useTranslation();

    return (
        <Modal open={isVisible} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div className="modalBox cofirmModal">
                <h2 className="modalBox-title">{t('home_page.desktop.offline_status_title')}</h2>
                <div className="modalBox-subtitle">
                    <span>{t('home_page.desktop.offline_status_subtitle')} </span>
                </div>
            </div>
        </Modal>
    );
};

export default OfflineModal;
