import { ReduxAction } from '../../models/reducers';
import { SavedType, User, WatchedType } from '../../models/user';

export enum USER_ACTIONS {
    SET_USER = 'userActions/login',
    SET_WATCHED_MOVIES_COUNT = 'userActions/setWatchedMoviesCount',
    SET_WATCHED_TV_SHOWS_COUNT = 'userActions/setWatchedTvShowsCount',
    SET_ASK_PREFERENCE = 'userActions/setAskPreference',
    SET_IS_INSTALLABLE_PWA = 'userActions/setIsInstallablePWA',
    LOGOUT = 'userActions/logout',
}

export const setUser: ReduxAction<User> = (user: User) => {
    return {
        type: USER_ACTIONS.SET_USER,
        payload: user,
    };
};

export const setWatchedMoviesCount: ReduxAction<number> = (count: number) => {
    return {
        type: USER_ACTIONS.SET_WATCHED_MOVIES_COUNT,
        payload: count,
    };
};

export const setWatchedTvShowsCount: ReduxAction<number> = (count: number) => {
    return {
        type: USER_ACTIONS.SET_WATCHED_TV_SHOWS_COUNT,
        payload: count,
    };
};

export const setAskPreference: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: USER_ACTIONS.SET_ASK_PREFERENCE,
        payload: status,
    };
};

export const setIsInstallablePwa: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: USER_ACTIONS.SET_IS_INSTALLABLE_PWA,
        payload: status,
    };
};

export const logoutUser = () => {
    return {
        type: USER_ACTIONS.LOGOUT,
    };
};
