import FilterStepQuestion from '../../../../components/FilterStepQuestion/FilterStepQuestion';
import TypeSelectorButton from '../../../../components/TypeSelectorButton';
import { COLORS } from '../../../../resources/constants';
import { ContentType } from '../../../../utilities/enum/contentType';
import useWindowDimensions from '../../../../utilities/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    onNext: (type: ContentType) => void;
}

const ChooseTypeStep: FC<Props> = ({ onNext }) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    return (
        <div className="filterContainer-content_step -type">
            <FilterStepQuestion number={1} text={t('filter_page.mobile.choose_type')} />
            <div className="filterContainer-content_step__row">
                <TypeSelectorButton
                    color={COLORS.purple}
                    text={t('filter_page.mobile.movie')}
                    size={width * 0.3}
                    onClick={() => {
                        onNext(ContentType.MOVIE);
                    }}
                />
                <TypeSelectorButton
                    color={COLORS.yellow}
                    text={t('filter_page.mobile.tv_show')}
                    size={width * 0.3}
                    onClick={() => {
                        onNext(ContentType.TV_SHOW);
                    }}
                />
            </div>
        </div>
    );
};

export default ChooseTypeStep;
