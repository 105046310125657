import React from 'react';
import Button from '../../../components/Button/Button';
import Paragraph from '../../../components/Paragraph';
import Title from '../../../components/Title';
import { useTranslation } from 'react-i18next';
import { ButtonColors } from '../../../utilities/enum/buttonColors';
import SendResetPasswordForm from '../../../components/Forms/SendResetPasswordForm';

interface Props {
    onBack: () => void;
}

const ResetPasswordPhase: React.FC<Props> = ({ onBack }) => {
    const { t } = useTranslation();

    return (
        <div className="loginContainer-content_formColumn__columnContent">
            <Title text={t('login_page.reset_password_title')} align="left" weight="bold" />
            <Paragraph>
                <p>{t('login_page.reset_password_paragraph')}</p>
            </Paragraph>
            <SendResetPasswordForm />
            <Paragraph>
                <Button
                    text={`← ${t('login_page.back_to_login')}`}
                    onClick={onBack}
                    styleClass={ButtonColors.LINK_WHITE}
                />
            </Paragraph>
        </div>
    );
};

export default ResetPasswordPhase;
