import { Feeling } from '../../models/data';
import {
    setLatestIdMovie,
    setLatestIdShow,
    setMovieGenres,
    setShowGenres,
    setMovieProviders,
    setShowProviders,
    setFeelings,
    setPopularBackdrops,
    setPopularPosters,
} from './data';
// import "firebase/compat/analytics";
import { getFeelings } from '../../api/db-requests';
import { getLatestMovie, getMoviesGenres, getMoviesProviders } from '../../api/movies-requests';
import { getLatestTvShow, getTvShowsGenres, getTvShowsProviders } from '../../api/tv-shows-requests';
import { getWatchedContentsCount } from '../../api/user-contents-requests';
import { ContentType } from '../../utilities/enum/contentType';
import { setWatchedMoviesCount, setWatchedTvShowsCount } from './user';
import { getPopularBackdrops, getPopularPosters } from '../../api/mixed-contents';

export const dispatchLatestIdMovie: any = () => async (dispatch: (any: any) => void) => {
    const latestId = await getLatestMovie();
    dispatch(setLatestIdMovie(latestId));
};

export const dispatchLatestIdShow: any = () => async (dispatch: (any: any) => void) => {
    const latestId = await getLatestTvShow();
    dispatch(setLatestIdShow(latestId));
};

export const dispatchMovieGenres: any = () => async (dispatch: (any: any) => void) => {
    const genres = await getMoviesGenres();
    dispatch(setMovieGenres(genres));
};

export const dispatchShowGenres: any = () => async (dispatch: (any: any) => void) => {
    const genres = await getTvShowsGenres();
    dispatch(setShowGenres(genres));
};

export const dispatchMovieProviders: any = () => async (dispatch: (any: any) => void) => {
    const providers = await getMoviesProviders();
    dispatch(setMovieProviders(providers));
};

export const dispatchShowProviders: any = () => async (dispatch: (any: any) => void) => {
    const providers = await getTvShowsProviders();
    dispatch(setShowProviders(providers));
};

export const dispatchFeelings: any = () => async (dispatch: (any: any) => void) => {
    let feelings: Feeling[] = [];
    const fetched = await getFeelings();
    if (fetched && Array.isArray(fetched)) {
        feelings = fetched;
    } else {
        feelings = [];
    }
    dispatch(setFeelings(feelings));
};

export const dispatchPopularBackdrops: any = () => async (dispatch: (any: any) => void) => {
    let backdrops: string[] = [];
    const fetched = await getPopularBackdrops();
    if (fetched && Array.isArray(fetched) && fetched.length > 0) {
        backdrops = fetched;
    } else {
        backdrops = [];
    }
    dispatch(setPopularBackdrops(backdrops));
};

export const dispatchPopularPosters: any = () => async (dispatch: (any: any) => void) => {
    let posters: string[] = [];
    const fetched = await getPopularPosters();
    if (fetched && Array.isArray(fetched) && fetched.length > 0) {
        posters = fetched.slice(0, 50);
    } else {
        posters = [];
    }
    dispatch(setPopularPosters(posters));
};

export const dispatchMoviesCountPerUser: any = (userId: number) => async (dispatch: (any: any) => void) => {
    const userCount = await getWatchedContentsCount(ContentType.MOVIE, userId);
    dispatch(setWatchedMoviesCount(userCount.count));
};

export const dispatchTvShowsCountPerUser: any = (userId: number) => async (dispatch: (any: any) => void) => {
    const userCount = await getWatchedContentsCount(ContentType.TV_SHOW, userId);
    dispatch(setWatchedTvShowsCount(userCount.count));
};
