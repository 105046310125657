import axios from 'axios';
import { Feeling, Filter } from '../models/data';
import { ContentType } from '../utilities/enum/contentType';
import { baseUrlPlatform } from '../resources/constants';

export const sendAnonymData = async (filter: Filter, type: ContentType, feeling?: number) => {
    const bodyAnononymSearchData = {
        genres: filter.genres,
        platforms: filter.providers ?? [],
        years: [filter.startDate.toString(), filter.endDate.toString()],
        isRegistered: false,
        feeling: feeling,
        type: type,
    };
    await axios.post(`${baseUrlPlatform}stats/anonym_search_data`, bodyAnononymSearchData);
};

export const getFeelings = async (): Promise<Feeling[]> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}feelings`)
            .then((response: any) => resolve(response.data.items))
            .catch((e) => {
                reject(e);
            });
    });
};
