import React from "react";

interface Props {
  children: any;
}

const Paragraph: React.FC<Props> = ({children}) => {
  return (
    <div style={{ color: "rgba(255,255,255,0.7)", fontSize: 20 }}>
      {children}
    </div>
  );
};

export default Paragraph;
