import React from 'react';
import { ContentType } from '../../utilities/enum/contentType';
import { useTranslation } from 'react-i18next';

import './Loader.scss';

interface Props {
    type?: ContentType | string;
    onlyLoader?: boolean;
    height?: string;
}

const Loader: React.FC<Props> = ({ type = '', onlyLoader = false, height = '100%' }) => {
    const { t } = useTranslation();

    if (!type) {
        return (
            <div
                className="loaderContainer"
                style={{
                    height: height,
                }}
            >
                {!onlyLoader ? <h2 className="loaderContainer-title">{t('loading.loading')}</h2> : null}
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }
    return (
        <div
            className="loaderContainer"
            style={{
                height: height,
            }}
        >
            <h2 className="loaderContainer-title">
                {type === ContentType.MOVIE ? t('loading.searching_movie') : t('loading.search_tv_show')}
            </h2>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loader;
