import React, { useMemo } from 'react';
import { Feeling } from '../../models/data';
import { useTranslation } from 'react-i18next';

import './FeelingItem.scss';

interface Props {
    feeling: Feeling;
    onClick: () => void;
    style?: any;
}

const FeelingItem: React.FC<Props> = ({ feeling, onClick, style = undefined }) => {
    const { t } = useTranslation();
    const urlFeeling: string = useMemo(() => {
        return `https://media.whatwatch.tv/feelings/${feeling.id}.png`;
    }, [feeling]);

    return (
        <div
            className="feelingItem"
            style={{
                ...style,
            }}
            onClick={() => onClick()}
        >
            <img src={urlFeeling} alt={feeling.name} onError={({ currentTarget }) => (currentTarget.src = '')} />
            <p>{t(`feelings.${feeling.label}`)}</p>
        </div>
    );
};

export default FeelingItem;
