import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import Paragraph from '../../components/Paragraph';
import Title from '../../components/Title';
import { ROUTES } from '../../resources/routes-constants';
import { ReducerUser, Reducers } from '../../models/reducers';
import './LoginPage.scss';
import LoginPhase from './Phases/LoginPhase';
import RegisterPhase from './Phases/RegisterPhase';
import ResetPasswordPhase from './Phases/ResetPasswordPhase';
import { useTranslation } from 'react-i18next';
import { ButtonColors } from '../../utilities/enum/buttonColors';
import FixedBackground from '../../components/FixedBackground/FixedBackground';
import ViewportSwitchComponent from '../../components/ViewportSwitchComponent';
import ZoomBackdropsBackground from '../../components/ZoomBackdropsBackground/ZoomBackdropsBackground';
import GridPostersBackground from '../../components/GridPostersBackground/GridPostersBackground';

enum Phase {
    LOGIN,
    REGISTER,
    RESET_PASSWORD,
}

const LoginPage: React.FC = () => {
    const { t } = useTranslation();
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const [phase, setPhase] = useState<Phase>(Phase.LOGIN);
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.hash.includes('register')) {
            setPhase(Phase.REGISTER);
        }
    }, []);

    useEffect(() => {
        if (user.user) {
            navigate(ROUTES.ACCOUNT_PAGE);
        }
    }, [navigate, user]);

    const switchPhase = () => {
        switch (phase) {
            case Phase.LOGIN:
                return (
                    <LoginPhase
                        onRegister={() => setPhase(Phase.REGISTER)}
                        onResetPassword={() => setPhase(Phase.RESET_PASSWORD)}
                    />
                );
            case Phase.REGISTER:
                return <RegisterPhase onLogin={() => setPhase(Phase.LOGIN)} />;
            case Phase.RESET_PASSWORD:
                return <ResetPasswordPhase onBack={() => setPhase(Phase.LOGIN)} />;
            default:
                return (
                    <LoginPhase
                        onRegister={() => setPhase(Phase.REGISTER)}
                        onResetPassword={() => setPhase(Phase.RESET_PASSWORD)}
                    />
                );
        }
    };

    const loginContainer = useMemo(() => {
        return (
            <div className="loginContainer">
                <FixedBackground />
                <Header isInDetail={false} isAccountPage closeAccount={() => navigate(ROUTES.HOME_PAGE)} />
                <div className="loginContainer-content">
                    <div className="loginContainer-content_formColumn">{switchPhase()}</div>
                    <div className="loginContainer-content_descriptionColumn">
                        <Title text={t('login_page.why_register_title')} align="left" weight="bold" />
                        <Paragraph>
                            <p>{t('login_page.why_register_first_paragraph')}</p>
                        </Paragraph>
                        <Paragraph>
                            <p>{t('login_page.why_register_second_paragraph')}</p>
                        </Paragraph>
                        <Paragraph>
                            <ul>
                                <li>{t('login_page.why_register_first_dot')}</li>
                                <li>{t('login_page.why_register_second_dot')}</li>
                                <li>{t('login_page.why_register_third_dot')}</li>
                            </ul>
                        </Paragraph>
                        <div className="loginContainer-content_descriptionColumn--actionRow">
                            <Button
                                styleClass={ButtonColors.WHITE_TRANSPARENT}
                                text={t('login_page.why_register_button')}
                                onClick={() => setPhase(Phase.REGISTER)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [navigate, switchPhase, setPhase, t]);

    return (
        <ViewportSwitchComponent
            desktopComponent={
                <>
                    <ZoomBackdropsBackground />
                    {loginContainer}
                </>
            }
            mobileComponent={
                <>
                    <GridPostersBackground />
                    {loginContainer}
                </>
            }
        />
    );
};

export default LoginPage;
