import { FC, useCallback, useRef, useState } from 'react';
import LoadingModal from '../Modals/LoadingModal';
import { useForm } from 'react-hook-form';
import { SendResetPasswordFormData } from '../../models/forms';
import Button from '../Button/Button';
import { sendResetPasswordEmail } from '../../api/users-requests';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ErrorBox from '../ErrorBox';
import SuccessBox from '../SuccessBox';
import { ButtonColors } from '../../utilities/enum/buttonColors';
import InputField from './Fields/InputField';
import { FormIds } from '../../utilities/enum/formIds';
import { testEmail } from '../../utilities/functions';
import { verifyRecaptcha } from '../../api/recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';

const SendResetPasswordForm: FC = () => {
    const {
        register,
        handleSubmit,
        trigger,
        reset,
        formState: { errors, isValid },
    } = useForm<SendResetPasswordFormData>({ mode: 'onBlur', criteriaMode: 'all' });
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');

    const checkValidEmail = useCallback(
        (newText: string) => {
            if (testEmail(newText)) {
                return true;
            } else {
                return t('errors.email_format_error');
            }
        },
        [t],
    );

    const handleSendResetMail = useCallback(
        async (formData: SendResetPasswordFormData) => {
            trigger();
            const token = await verifyRecaptcha(recaptchaRef.current as ReCAPTCHA);
            if (isValid && token) {
                setError('');
                setLoading(true);
                try {
                    await sendResetPasswordEmail(formData.email, token);
                    reset();
                    setSuccess(t('login_page.reset_password_email_sent'));
                } catch (error: any) {
                    if (error.error == 'email_not_found') {
                        setSuccess(t('login_page.reset_password_email_sent'));
                    } else {
                        setError(t(`errors.${error.error}`) ?? t('errors.generic_reset_password_error'));
                    }
                }
                setLoading(false);
            } else {
                setError(t('errors.fill_login_form_error'));
            }
        },
        [dispatch, trigger, isValid, t],
    );

    return (
        <>
            <form onSubmit={handleSubmit(handleSendResetMail)}>
                <InputField
                    type="email"
                    id={FormIds.EMAIL}
                    register={register}
                    errors={errors[FormIds.EMAIL] ?? null}
                    placeholder={t('login_page.email')}
                    label=""
                    isRequired
                    validate={checkValidEmail}
                />
                {error ? <ErrorBox message={error} /> : null}
                {success ? (
                    <SuccessBox message={success} />
                ) : (
                    <Button
                        isSubmit
                        styleClass={ButtonColors.WHITE_TRANSPARENT}
                        text={t('login_page.send_email')}
                        onClick={() => {}}
                    />
                )}

                <ReCAPTCHA
                    size="invisible"
                    theme="dark"
                    sitekey={process.env.REACT_APP_RECAPTCHA_ID as string}
                    ref={recaptchaRef}
                />
            </form>
            <LoadingModal isVisible={loading} />
        </>
    );
};

export default SendResetPasswordForm;
