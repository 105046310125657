import { editUser } from '../../../../api/users-requests';
import Button from '../../../../components/Button/Button';
import ChangeType from '../../../../components/ChangeType/ChangeType';
import LoadingModal from '../../../../components/Modals/LoadingModal';
import MultiFilterSelector from '../../../../components/MultiFilterSelector/MultiFilterSelector';
import Toast from '../../../../components/Toast';
import { Genre } from '../../../../models/data';
import { ReducerData, Reducers } from '../../../../models/reducers';
import { User } from '../../../../models/user';
import { setUser } from '../../../../store/actions/user';
import { ButtonColors } from '../../../../utilities/enum/buttonColors';
import { ContentType } from '../../../../utilities/enum/contentType';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
    user: User | null;
    onChangeType: (type: ContentType) => void;
}

const GenresView: FC<Props> = ({ user, onChangeType }) => {
    const { t } = useTranslation();
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const [selectedType, setSelectedType] = useState<ContentType>(ContentType.MOVIE);
    const [selectedGenres, setSelectedGenres] = useState(user?.genres ?? []);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const dispatch = useDispatch();

    const handleCloseToast = useCallback(() => {
        setError('');
        setSuccess('');
    }, []);

    const handleChangeArray = useCallback(
        (genres: Genre[]) => {
            const genresIds = genres.map((genre) => genre.id);
            setSelectedGenres(genresIds);
        },
        [selectedGenres],
    );

    const handleSave = useCallback(() => {
        if (user) {
            setLoading(true);
            editUser(user.id, { platforms: user.platforms, genres: selectedGenres })
                .then((response) => {
                    if ((response as User).id && user) {
                        dispatch(
                            setUser({
                                ...user,
                                genres: selectedGenres,
                            }),
                        );
                        setSuccess(t(`account_page.desktop.preferences.preferences_saved`));
                    } else {
                        setError(t(`errors.save_preferences_error`));
                    }
                })
                .catch((error) => setError(t(`errors.${error.error}`)))
                .finally(() => setLoading(false));
        }
    }, [dispatch, selectedGenres, t, user]);

    const selectedGenresArray = useMemo(() => {
        const arraySelected: Genre[] = [];
        if (user) {
            if (selectedType === ContentType.MOVIE) {
                for (const genre of data.movieGenres) {
                    if (selectedGenres.includes(genre.id)) {
                        arraySelected.push(genre);
                    }
                }
            } else {
                for (const genre of data.showGenres) {
                    if (selectedGenres.includes(genre.id)) {
                        arraySelected.push(genre);
                    }
                }
            }
        }
        return arraySelected;
    }, [user, selectedType, data.movieGenres, data.showGenres, selectedGenres]);

    useEffect(() => {
        onChangeType(selectedType);
    }, [selectedType]);

    return (
        <div className="accountMobileContainer-content -platform">
            <h2 className="accountMobileContainer-content_subtitle">{t('account_page.mobile.select_genres')}</h2>
            <ChangeType selectedType={selectedType} onChange={(type) => setSelectedType(type)} />
            <MultiFilterSelector
                datas={selectedType === ContentType.MOVIE ? data.movieGenres : data.showGenres}
                type={'genre'}
                contentType={selectedType}
                selectedDatas={selectedGenresArray}
                onChange={(newSelected) => {
                    const genres = newSelected as Genre[];
                    handleChangeArray(genres);
                }}
            />
            <Button
                styleClass={ButtonColors.WHITE_TRANSPARENT}
                onClick={handleSave}
                text={t('account_page.mobile.save')}
            />
            <LoadingModal isVisible={loading} />
            <Toast message={error || success} onClose={handleCloseToast} type={success ? 'success' : 'error'} />
        </div>
    );
};

export default GenresView;
