import { BeforeInstallPromptEvent } from '../../models/general';
import { Reducers } from '../../models/reducers';
import { setIsInstallablePwa } from '../../store/actions/user';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import LOGO from '../../resources/images/whatwatch-logo.svg';
import Button from '../../components/Button/Button';
import { isIOS, isMobile, isMobileOnly, isMobileSafari, isTablet } from 'react-device-detect';
import Icon from '../../components/Icon/Icon';
import { COLORS } from '../../resources/constants';

import './InstallPwaBanner.scss';
import { ButtonColors } from '../../utilities/enum/buttonColors';

declare global {
    interface Window {
        deferredPrompt: BeforeInstallPromptEvent | null;
    }
}

const InstallPwaBanner: FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isInstallablePwa } = useSelector((store: Reducers) => store.user);
    const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);

    const handleCancelClick = useCallback(async () => {
        dispatch(setIsInstallablePwa(false));
    }, [dispatch]);

    useEffect(() => {
        if (!isIOS) {
            const handler = (event: Event) => {
                event.preventDefault();
                setDeferredPrompt(event as BeforeInstallPromptEvent);
                dispatch(setIsInstallablePwa(true));
            };

            window.addEventListener('beforeinstallprompt', handler);

            return () => {
                window.removeEventListener('beforeinstallprompt', handler);
            };
        }
    }, [dispatch]);

    const handleInstallClick = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log("Utente ha accettato l'installazione");
                } else {
                    console.log("Utente ha rifiutato l'installazione");
                }
                window.deferredPrompt = null;
            });
            setDeferredPrompt(null);
            dispatch(setIsInstallablePwa(false));
        }
    };

    if (isInstallablePwa && isIOS) {
        return (
            <div className="installBanner">
                <img src={LOGO} width={40} height={40} className="installBanner-logo" alt="WhatWatch Logo" />
                <div className="installBanner-description">
                    <p>
                        {t('general.install_pwa_ios_click')} <Icon name="share-apple" size={26} color={COLORS.purple} />{' '}
                        {t('general.install_pwa_ios_instructions')}
                    </p>
                    <div className="installBanner-description_actionsRow">
                        <Button
                            onClick={handleCancelClick}
                            text={t('general.hide_message')}
                            styleClass={ButtonColors.LINK_MOVIE}
                        />
                    </div>
                </div>
            </div>
        );
    }

    if (isInstallablePwa && (isMobileOnly || isMobile || isMobileSafari || isTablet)) {
        return (
            <div className="installBanner">
                <img src={LOGO} width={40} height={40} className="installBanner-logo" alt="WhatWatch Logo" />
                <div className="installBanner-description">
                    <p>{t('general.install_pwa_title')}</p>
                    <div className="installBanner-description_actionsRow">
                        <Button
                            onClick={handleInstallClick}
                            text={t('general.install_pwa_cta')}
                            styleClass={ButtonColors.LINK_MOVIE}
                        />
                        <Button
                            onClick={handleCancelClick}
                            text={t('general.cancel_install_cta')}
                            styleClass={ButtonColors.LINK_OPACITY}
                        />
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

export default InstallPwaBanner;
