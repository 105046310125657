import React from "react";

interface Props {
  text: string;
  align?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  weight?: string;
  flex?: number;
}

const Title: React.FC<Props> = ({text, align = 'center', weight = 'normal', flex = 0}) => {
  return (
    <h2
      style={{
        fontSize: 30,
        fontWeight: weight ? weight : "normal",
        color: "#fff",
        textAlign: align ? align : "center",
        flex: flex ? flex : 0,
      }}
    >
      {text}
    </h2>
  );
};

export default Title;
