import { FC, useCallback, useMemo } from 'react';
import SuccessBox from '../../../components/SuccessBox';
import Paragraph from '../../../components/Paragraph';
import Button from '../../../components/Button/Button';
import ErrorBox from '../../../components/ErrorBox';
import LoadingModal from '../../../components/Modals/LoadingModal';
import Header from '../../../components/Header/Header';
import { Password } from '../ResetPasswordPage';
import { testPassword } from '../../../utilities/functions';

import './ResetPasswordPageDesktop.scss';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../resources/routes-constants';
import { useNavigate } from 'react-router-dom';
import ResetPasswordForm from '../../../components/Forms/ResetPasswordForm';

interface Props {
    token: string;
}

const ResetPasswordPageDesktop: FC<Props> = ({ token }) => {
    const { t } = useTranslation();

    return (
        <div className="resetPasswordContainer">
            <Header isInDetail={false} isResetPasswordPage />
            <div className="resetPasswordContainer-content">
                <Paragraph>
                    <p>{t('reset_password_page.insert_password')}</p>
                </Paragraph>
                <ResetPasswordForm token={token} />
            </div>
        </div>
    );
};

export default ResetPasswordPageDesktop;
