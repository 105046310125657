import { FC } from 'react';
import StyledSwitch from '../StyledSwitch';

import './SettingsItem.scss';
interface Props {
    key: string;
    title: string;
    subtitle: string;
    value: boolean;
    onChange: () => void;
}

const SettingsItem: FC<Props> = ({ key, title, subtitle, value, onChange }) => {
    return (
        <div className="settingsItem">
            <div className="settingsItem_columnDescription">
                <h2>{title}</h2>
                <p>{subtitle}</p>
            </div>
            <StyledSwitch checked={value} onChange={onChange} name={key} />
        </div>
    );
};

export default SettingsItem;
