import Slider from '@mui/material/Slider';
import React from 'react';
import { COLORS } from '../../resources/constants';
import { getYear } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ContentType } from '../../utilities/enum/contentType';

import './YearRangeSelector.scss';

interface Props {
    selectedRange: number[];
    type: ContentType;
    onChange: (range: number[]) => void;
    orientation?: 'horizontal' | 'vertical';
}

const YearRangeSelector: React.FC<Props> = ({ selectedRange, type, onChange, orientation = 'vertical' }) => {
    const { t } = useTranslation();

    const getDecades = () => {
        const difference = getYear(new Date()) - 1950;
        const arrayDecades = [];
        for (let i = 0; i < difference; i++) {
            if (i % 10 === 0) {
                arrayDecades.push({
                    value: 1950 + i,
                });
            }
        }
        return arrayDecades;
    };

    return (
        <div className="yearSelectorContainer">
            {orientation === 'vertical' ? <h3>{t('filter_page.desktop.year')}</h3> : null}
            <div className="yearSelectorContainer-sliderYear">
                <Slider
                    orientation={orientation}
                    valueLabelDisplay={orientation === 'horizontal' ? 'off' : 'on'}
                    value={selectedRange}
                    min={1950}
                    max={getYear(new Date())}
                    step={1}
                    marks={getDecades()}
                    onChange={(_, values) => {
                        onChange(values as number[]);
                    }}
                    sx={{
                        color: type === ContentType.MOVIE ? COLORS.purple : COLORS.yellow,
                        '& .MuiSlider-thumb': {
                            backgroundColor: type === ContentType.MOVIE ? COLORS.purple : COLORS.yellow,
                        },
                        '& .MuiSlider-thumb:hover': {
                            boxShadow:
                                type === ContentType.MOVIE
                                    ? '0 0 0 8px rgba(243,22,180,0.16)'
                                    : '0 0 0 8px rgba(247,214,50,0.16)',
                        },
                        '& .MuiSlider-thumb.Mui-focusVisible': {
                            boxShadow:
                                type === ContentType.MOVIE
                                    ? '0 0 0 8px rgba(243,22,180,0.16)'
                                    : '0 0 0 8px rgba(247,214,50,0.16)',
                        },
                        '& .MuiSlider-thumb.Mui-active': {
                            boxShadow:
                                type === ContentType.MOVIE
                                    ? '0 0 0 8px rgba(243,22,180,0.16)'
                                    : '0 0 0 8px rgba(247,214,50,0.16)',
                        },
                        '& .MuiSlider-mark': {
                            width: orientation === 'horizontal' ? 2 : 7,
                            height: orientation === 'horizontal' ? 7 : 2,
                            left: 30,
                            transform: 'none',
                            backgroundColor: '#ebebeb',
                            opacity: 0.2,
                        },
                        '& .MuiSlider-markActive': {
                            backgroundColor: '#ebebeb',
                            opacity: 0.2,
                        },
                        '& .MuiSlider-valueLabel': {
                            right: 'auto',
                            left: 30,
                            background: 'transparent',
                            fontSize: 16,
                            padding: '0 15px',
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default YearRangeSelector;
