import { FC, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ROUTES } from '../resources/routes-constants';
import HomePage from './HomePage/HomePage';
import ResultPage from './ResultPage/ResultPage';
import FilterPage from './FilterPage/FilterPage';
import LoginPage from './LoginPage/LoginPage';
import ResetPasswordPage from './ResetPasswordPage/ResetPasswordPage';
import AccountPage from './AccountPage/AccountPage';
import FeelingsPage from './FeelingsPage/FeelingsPage';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ReducerUser, Reducers } from '../models/reducers';
import NotFoundPage from './NotFoundPage/NotFoundPage';

const AppRouter: FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user);

    useEffect(() => {
        if (user.user) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${user.user.token}`;
        }
    }, [user.user]);

    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route path={ROUTES.HOME_PAGE} element={<HomePage />} />
                <Route path={ROUTES.FEELINGS_PAGE} element={<FeelingsPage />} />
                <Route path={ROUTES.RESULT_PAGE} element={<ResultPage />} />
                <Route path={`${ROUTES.RESULT_PAGE}/:contentId`} element={<ResultPage />} />
                <Route path={ROUTES.FILTER_PAGE} element={<FilterPage />} />
                <Route path={ROUTES.LOGIN_PAGE} element={<LoginPage />} />
                <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
                <Route path={ROUTES.ACCOUNT_PAGE} element={<AccountPage />} />
                <Route path={'*'} element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;
