import { FC } from 'react';

import { FieldError, FieldErrorsImpl, Merge, UseFormRegister } from 'react-hook-form';

import Icon from '../../Icon/Icon';
import { FormIds } from '../../../utilities/enum/formIds';
import { LoginFormData } from '../../../models/forms';
import './Fields.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    label: string;
    placeholder: string;
    // ts-ignore
    register: UseFormRegister<LoginFormData | any>;
    errors: FieldError | Merge<FieldError, FieldErrorsImpl<LoginFormData>> | null;
    id: FormIds;
    icon?: string;
    onClickIcon?: () => void;
    validate?: (value: string) => string | boolean;
    isRequired?: boolean;
    requiredMessage?: string;
    type?: string;
    onlyFormField?: boolean;
}

const InputField: FC<Props> = ({
    label,
    placeholder,
    register,
    validate,
    errors,
    icon = '',
    onClickIcon,
    id,
    isRequired = false,
    requiredMessage = 'general.forms.field_required',
    type = 'text',
    onlyFormField = false,
}) => {
    const { t } = useTranslation();

    if (onlyFormField) {
        return (
            <input
                type={type}
                placeholder={placeholder}
                {...register(id, { required: isRequired ? t(requiredMessage) : false, validate })}
            />
        );
    }

    return (
        <div className="formField -text">
            {label && <label className="sag-copy -textXL">{label}</label>}
            <div className={`formField_container ${errors && errors.message ? '-error' : ''}`}>
                <input
                    type={type}
                    placeholder={placeholder}
                    {...register(id, { required: isRequired ? t(requiredMessage) : false, validate })}
                />
                {icon && <Icon name={icon} onClick={onClickIcon} size={18} color={'white'} />}
            </div>
            {errors && errors.message && <p className="formField_error">{errors.message}</p>}
        </div>
    );
};

export default InputField;
