import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { availableLanguages, defaultLanguage, languagesResources } from './languageConfig';

const fallbackLng = defaultLanguage;

i18n.use(LanguageDetector)
    // connect with React
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: languagesResources,

        debug: true,

        lng: defaultLanguage,
        fallbackLng,
        supportedLngs: availableLanguages,

        interpolation: {
            escapeValue: true, // not needed for react as it escapes by default
        },

        react: {
            useSuspense: true,
            transKeepBasicHtmlNodesFor: ['p', 'strong', 'span', 'underline'],
        },
    });

export default i18n;
