import ViewportSwitchComponent from '../../components/ViewportSwitchComponent';
import { ROUTES } from '../../resources/routes-constants';
import { FC, useCallback, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import FeelingsPageMobile from './Mobile/FeelingsPageMobile';
import { Filter, Genre, Provider } from '../../models/data';
import { useSelector } from 'react-redux';
import { ReducerData, ReducerUser, Reducers } from '../../models/reducers';
import { initialFilter } from '../../pages/FilterPage/FilterPage';
import { getUserProvidersArray } from '../../utilities/functions';
import { sendAnonymData } from '../../api/db-requests';
import { ContentType } from '../../utilities/enum/contentType';
import { postFBViewContentEvent } from '../../api/analytics-requests';

const FeelingsPage: FC = () => {
    const navigate = useNavigate();
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const data: ReducerData = useSelector((store: Reducers) => store.data);

    const fetchResult = useCallback(
        async (genres: Genre[], feelingId: number, selectedType: ContentType) => {
            if (selectedType) {
                let filter: Filter = { ...initialFilter, genres: genres };
                if (user.user && user.user.settings.randomSearchWithPreferences) {
                    const providers: Provider[] = getUserProvidersArray(
                        user.user.platforms,
                        data.movieProvider,
                        data.showProvider,
                    );
                    filter = { ...filter, providers };
                }
                if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
                    sendAnonymData(filter, selectedType, feelingId);
                    postFBViewContentEvent(selectedType, 'feeling', user.user != null, user.user?.email || null);
                }
                navigate(ROUTES.RESULT_PAGE, {
                    state: {
                        type: selectedType,
                        filter: filter,
                        isFeeling: true,
                    },
                });
            }
        },
        [user.user, navigate, data.movieProvider, data.showProvider],
    );

    useEffect(() => {
        if (isDesktop) {
            navigate(ROUTES.HOME_PAGE);
        }
    }, [navigate]);

    return (
        <ViewportSwitchComponent
            desktopComponent={<div />}
            mobileComponent={<FeelingsPageMobile onSelect={fetchResult} />}
        />
    );
};

export default FeelingsPage;
