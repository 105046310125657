import { FC } from 'react';
import { ContentType } from '../../utilities/enum/contentType';

import './Chip.scss';

interface Props {
    name: string;
    type?: ContentType;
    selected?: boolean;
    onClick?: () => void;
}

const Chip: FC<Props> = ({ name, type, selected = false, onClick }) => {
    return (
        <div
            className={`chip ${selected ? '-selected' : ''} ${
                type ? (type === ContentType.MOVIE ? '-movie' : '-show') : ''
            }`}
            onClick={
                onClick
                    ? onClick
                    : () => {
                          return;
                      }
            }
            style={{ cursor: onClick ? 'pointer' : 'inherit' }}
        >
            {name}
        </div>
    );
};

export default Chip;
