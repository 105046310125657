import ChangeType from '../../../../components/ChangeType/ChangeType';
import ContentsGridComponent from '../../../../components/ContentsGridComponent/ContentsGridComponent';
import Loader from '../../../../components/Loader/Loader';
import LoadingModal from '../../../../components/Modals/LoadingModal';
import Toast from '../../../../components/Toast';
import { ContentType } from '../../../../utilities/enum/contentType';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    type: 'watched' | 'saved';
    onChangeType: (type: ContentType) => void;
}

const ContetsListView: FC<Props> = ({ type, onChangeType }) => {
    const { t } = useTranslation();
    const [selectedType, setSelectedType] = useState<ContentType>(ContentType.MOVIE);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadMore, setLoadMore] = useState(false);

    const handleCloseToast = useCallback(() => {
        setError('');
        setSuccess('');
    }, []);

    useEffect(() => {
        onChangeType(selectedType);
    }, [selectedType]);

    return (
        <div className="accountMobileContainer-content -contents">
            <h2 className="accountMobileContainer-content_subtitle">
                {type === 'saved' ? t('account_page.mobile.saved') : t('account_page.mobile.watched')}
            </h2>
            <ChangeType selectedType={selectedType} onChange={(type) => setSelectedType(type)} />
            <div className="accountMobileContainer-content_list">
                <ContentsGridComponent
                    type={selectedType}
                    list={type}
                    loadMore={loadMore}
                    onSuccess={setSuccess}
                    onError={setError}
                    onLoading={setLoading}
                    onLoadMore={setLoadMore}
                />
            </div>
            {loadMore ? <Loader onlyLoader={true} height="40px" /> : null}
            <LoadingModal isVisible={loading} />
            <Toast message={success || error} onClose={handleCloseToast} type={success ? 'success' : 'error'} />
        </div>
    );
};

export default ContetsListView;
