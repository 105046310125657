import { useNavigate } from 'react-router-dom';
import LOGO from '../../../resources/images/whatwatch-logo.svg';
import { ROUTES } from '../../../resources/routes-constants';
import ButtonIcon from '../../ButtonIcon/ButtonIcon';
import { ReducerUser, Reducers } from '../../../models/reducers';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../../utilities/hooks';
import TypeSelectorButton from '../../../components/TypeSelectorButton';
import { COLORS } from '../../../resources/constants';
import { ContentType } from '../../../utilities/enum/contentType';
import { useTranslation } from 'react-i18next';

import './HeaderMobile.scss';

interface Props {
    isInDetail: boolean;
    hideLogo?: boolean;
    type?: ContentType | string;
    isAccountPage?: boolean;
    isFilterPage?: boolean;
    isResetPasswordPage?: boolean;
    hideOtherSearchButtons?: boolean;
    otherFilmClick?: () => void;
    otherShowClick?: () => void;
    closeAccount?: () => void;
    tabs?: JSX.Element[];
}

const HeaderMobile: React.FC<Props> = ({
    isInDetail,
    hideLogo = false,
    isAccountPage = false,
    isResetPasswordPage = false,
    isFilterPage,
    hideOtherSearchButtons = false,
    otherFilmClick = () => {
        return;
    },
    otherShowClick = () => {
        return;
    },
    closeAccount,
    tabs = [],
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const user: ReducerUser = useSelector((store: Reducers) => store.user);

    if (isResetPasswordPage) {
        return (
            <div className="header">
                <img
                    src={LOGO}
                    alt="logo WhatWatch"
                    className="header-logo"
                    onClick={() => navigate(ROUTES.HOME_PAGE)}
                />
            </div>
        );
    }

    return (
        <>
            <div className="header">
                {!hideLogo ? (
                    <img
                        src={LOGO}
                        alt="logo WhatWatch"
                        className="header-logo"
                        onClick={() => navigate(ROUTES.HOME_PAGE)}
                    />
                ) : (
                    <div />
                )}
                <div className="header-actionColumn">
                    <div className="header-actionColumn_detailActions">
                        {isInDetail && !hideOtherSearchButtons ? (
                            <>
                                <div className="header-actionColumn_detailActions-actionButton">
                                    <TypeSelectorButton
                                        onClick={otherFilmClick}
                                        color={COLORS.purple}
                                        size={width * 0.08}
                                    />
                                    <span>{t('general.movie')}</span>
                                </div>
                                <div className="header-actionColumn_detailActions-actionButton">
                                    <TypeSelectorButton
                                        onClick={otherShowClick}
                                        color={COLORS.yellow}
                                        size={width * 0.08}
                                    />
                                    <span>{t('general.tv_show')}</span>
                                </div>
                            </>
                        ) : null}
                    </div>

                    {closeAccount ? (
                        <ButtonIcon
                            icon="close"
                            style={{ background: 'transparent' }}
                            size={32}
                            onClick={closeAccount}
                        />
                    ) : (
                        <ButtonIcon
                            icon="user"
                            size={width * 0.12}
                            onClick={() => {
                                if (user.user) {
                                    navigate(ROUTES.ACCOUNT_PAGE);
                                } else {
                                    navigate(ROUTES.LOGIN_PAGE);
                                }
                            }}
                            style={{
                                background: 'transparent',
                                fontSize: 32,
                                width: 32,
                                height: 32,
                                paddingTop: 5,
                            }}
                        />
                    )}
                </div>
            </div>
            {isAccountPage ? (
                <div className="header-title">{isAccountPage ? <h1>{t('header.account')}</h1> : null}</div>
            ) : null}
            {isFilterPage ? (
                <div className="header-title">{isFilterPage ? <h1>{t('header.filter')}</h1> : null}</div>
            ) : null}
        </>
    );
};

export default HeaderMobile;
