import { FC } from 'react';
import './FilterStepQuestion.scss';

interface Props {
    number: number;
    text: string;
}

const FilterStepQuestion: FC<Props> = ({ number, text }) => {
    return (
        <div className="filterStepQuestion">
            <span className="filterStepQuestion-number">{number}.</span>
            <h3 className="filterStepQuestion-text">{text}</h3>
        </div>
    );
};

export default FilterStepQuestion;
