import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../../components/Button/Button';
import { sendAnonymData } from '../../../../api/db-requests';
import { ROUTES } from '../../../../resources/routes-constants';
import { Filter, Genre, Provider } from '../../../../models/data';
import { initialFilter } from '../../../FilterPage/FilterPage';
import { ReducerData, ReducerUser, Reducers } from '../../../../models/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MultiFilterSelector from '../../../../components/MultiFilterSelector/MultiFilterSelector';
import { editUser } from '../../../../api/users-requests';
import { setUser } from '../../../../store/actions/user';
import LoadingModal from '../../../../components/Modals/LoadingModal';
import ChangeType from '../../../../components/ChangeType/ChangeType';
import Toast from '../../../../components/Toast';
import { getUserGenresArray, getUserProvidersArray } from '../../../../utilities/functions';
import { User } from '../../../../models/user';
import { useTranslation } from 'react-i18next';
import { ContentType } from '../../../../utilities/enum/contentType';
import { postFBViewContentEvent } from '../../../../api/analytics-requests';
import { ButtonColors } from '../../../../utilities/enum/buttonColors';

const SearchPreferencesTab: FC = () => {
    const { t } = useTranslation();
    const [selectedType, setSelectedType] = useState<ContentType>(ContentType.MOVIE);
    const [filter, setFilter] = useState<Filter>(initialFilter);
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleCloseToast = useCallback(() => {
        setError('');
        setSuccess('');
    }, []);

    const handleSearch = useCallback(() => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
            postFBViewContentEvent(selectedType, 'filtered', user.user != null, user.user?.email || null);
            sendAnonymData(filter, selectedType, -1);
        }
        navigate(ROUTES.RESULT_PAGE, {
            state: {
                type: selectedType,
                filter: filter,
            },
        });
    }, [navigate, selectedType, filter]);

    const handleSetPreference = useCallback(() => {
        if (user.user) {
            setLoading(true);
            const platforms = filter.providers.map((provider) => {
                return provider.id;
            });
            const genres = filter.genres.map((genre) => {
                return genre.id;
            });
            editUser(user.user.id, { platforms, genres })
                .then((response) => {
                    if ((response as User).id && user.user) {
                        dispatch(
                            setUser({
                                ...user.user,
                                platforms,
                                genres,
                            }),
                        );
                        setSuccess(t(`account_page.desktop.preferences.preferences_saved`));
                    } else {
                        setError(t(`errors.save_preferences_error`));
                    }
                })
                .catch((error) => setError(t(`errors.${error.error}`)))
                .finally(() => setLoading(false));
        }
    }, [user.user, filter.providers, filter.genres, dispatch, t]);

    const convertPlatformsInProviders = useMemo(() => {
        if (user.user && user.user.platforms) {
            const providers = getUserProvidersArray(user.user.platforms, data.movieProvider, data.showProvider);
            return providers;
        } else {
            return [];
        }
    }, [data.movieProvider, data.showProvider, user.user]);

    const startFilter = useMemo(() => {
        if (user.user) {
            const genres = getUserGenresArray(user.user.genres, data.movieGenres, data.showGenres);
            return {
                ...initialFilter,
                genres,
                providers: convertPlatformsInProviders,
            };
        } else {
            return initialFilter;
        }
    }, [convertPlatformsInProviders, data.movieGenres, data.showGenres, user.user]);

    useEffect(() => {
        setFilter(startFilter);
    }, [startFilter]);

    return (
        <div className="accountContainer-tabRendered_filterContainer">
            <ChangeType selectedType={selectedType} onChange={(type) => setSelectedType(type)} />
            <div className="accountContainer-tabRendered_filterContainer-filterSelection">
                <div style={{ flex: 2.5 }}>
                    <MultiFilterSelector
                        title={t('account_page.desktop.preferences.genres')}
                        contentType={selectedType}
                        type={'genre'}
                        datas={selectedType === ContentType.MOVIE ? data.movieGenres : data.showGenres}
                        selectedDatas={filter.genres}
                        onChange={(d) => {
                            const datas = d as Genre[];
                            setFilter({
                                ...filter,
                                genres: datas,
                            });
                        }}
                    />
                </div>
                <div style={{ flex: 2.5 }}>
                    <MultiFilterSelector
                        title={t('account_page.desktop.preferences.platforms')}
                        contentType={selectedType}
                        type={'provider'}
                        datas={selectedType === ContentType.MOVIE ? data.movieProvider : data.showProvider}
                        selectedDatas={filter.providers}
                        onChange={(d) => {
                            const datas = d as Provider[];
                            setFilter({
                                ...filter,
                                providers: datas,
                            });
                        }}
                    />
                </div>
            </div>
            <div className="accountContainer-tabRendered_filterContainer-buttonRow">
                <Button
                    text={
                        selectedType === ContentType.MOVIE
                            ? t('account_page.desktop.preferences.search_movie')
                            : t('account_page.desktop.preferences.search_tv_show')
                    }
                    onClick={handleSearch}
                    styleClass={ButtonColors.WHITE_TRANSPARENT}
                />
                <Button
                    text={t('account_page.desktop.preferences.set_preference')}
                    onClick={handleSetPreference}
                    styleClass={ButtonColors.WHITE_TRANSPARENT}
                    disabled={JSON.stringify(startFilter) === JSON.stringify(filter)}
                />
            </div>
            <LoadingModal isVisible={loading} />
            <Toast message={error || success} onClose={handleCloseToast} type={success ? 'success' : 'error'} />
        </div>
    );
};

export default SearchPreferencesTab;
