import { FC, useCallback, useEffect } from 'react';
import ViewportSwitchComponent from '../../components/ViewportSwitchComponent';
import AccountPageDesktop from './Desktop/AccountPageDesktop';
import AccountPageMobile from './Mobile/AccountPageMobile';
import { ReducerUser, Reducers } from '../../models/reducers';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../store/actions/user';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';

const AccountPage: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user: ReducerUser = useSelector((store: Reducers) => store.user);

    const handleLogout = useCallback(() => {
        delete axios.defaults.headers.common['Authorization'];
        dispatch(logoutUser());
        navigate(ROUTES.LOGIN_PAGE);
    }, [dispatch, navigate]);

    useEffect(() => {
        if (!user.user) {
            handleLogout();
        }
    }, [handleLogout, user]);

    return (
        <ViewportSwitchComponent
            desktopComponent={<AccountPageDesktop user={user.user} onLogout={handleLogout} />}
            mobileComponent={<AccountPageMobile user={user.user} onLogout={handleLogout} />}
        />
    );
};

export default AccountPage;
