import { FC, useCallback, useRef, useState } from 'react';
import LoadingModal from '../Modals/LoadingModal';
import { useForm } from 'react-hook-form';
import { LoginFormData } from '../../models/forms';
import Button from '../Button/Button';
import { loginUser, sendConfirmEmail } from '../../api/users-requests';
import { ResultType, User } from '../../models/user';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/actions/user';
import ErrorBox from '../ErrorBox';
import SuccessBox from '../SuccessBox';
import { ButtonColors } from '../../utilities/enum/buttonColors';
import InputField from './Fields/InputField';
import { FormIds } from '../../utilities/enum/formIds';
import { testEmail } from '../../utilities/functions';
import { verifyRecaptcha } from '../../api/recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';

const LoginForm: FC = () => {
    const {
        register,
        handleSubmit,
        trigger,
        formState: { errors, isValid },
    } = useForm<LoginFormData>({ mode: 'onBlur', criteriaMode: 'all' });
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [notConfirmed, setNotConfirmed] = useState(false);
    const [success, setSuccess] = useState('');
    const [visiblePassword, setVisiblePassword] = useState(false);

    const checkValidEmail = useCallback(
        (newText: string) => {
            if (testEmail(newText)) {
                return true;
            } else {
                return t('errors.email_format_error');
            }
        },
        [t],
    );

    const changeVisiblePassword = useCallback(() => {
        setVisiblePassword(!visiblePassword);
    }, [visiblePassword]);

    const handleLogin = useCallback(
        async (formData: LoginFormData) => {
            trigger();
            const token = await verifyRecaptcha(recaptchaRef.current as ReCAPTCHA);
            if (isValid && token) {
                try {
                    setLoading(true);
                    if (notConfirmed) {
                        await sendConfirmEmail(formData.email)
                            .then((data) => {
                                if ((data as ResultType).status === 'ok') {
                                    setSuccess(t('login_page.sent_confirm_email', { email: formData.email }));
                                    setError('');
                                    setNotConfirmed(false);
                                } else {
                                    setError(t('errors.generic_login_error'));
                                }
                            })
                            .catch((error) => setError(error.toString()))
                            .finally(() => setLoading(false));
                    } else {
                        await loginUser(formData, token)
                            .then((user) => {
                                if ((user as User).id) {
                                    const userData = user as User;
                                    axios.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`;
                                    dispatch(setUser(userData));
                                }
                            })
                            .catch((error) => {
                                if (error && error.error) {
                                    setError(t(`errors.${error.error}`) ?? t('errors.generic_login_error'));
                                }
                            })
                            .finally(() => setLoading(false));
                    }
                } catch (error: any) {
                    setError(t(`errors.${error.error}`) ?? t('errors.generic_login_error'));
                }
            } else {
                setError(t('errors.fill_login_form_error'));
            }
        },
        [dispatch, trigger, isValid, notConfirmed, t],
    );

    return (
        <>
            <form onSubmit={handleSubmit(handleLogin)}>
                <InputField
                    type="email"
                    id={FormIds.EMAIL}
                    register={register}
                    errors={errors[FormIds.EMAIL] ?? null}
                    placeholder={t('login_page.email')}
                    label=""
                    isRequired
                    validate={checkValidEmail}
                />
                <InputField
                    id={FormIds.PASSWORD}
                    register={register}
                    errors={errors[FormIds.PASSWORD] ?? null}
                    placeholder={t('login_page.password')}
                    label=""
                    type={visiblePassword ? 'text' : 'password'}
                    icon={visiblePassword ? 'eye-slash' : 'eye'}
                    onClickIcon={changeVisiblePassword}
                    isRequired
                />
                {error ? <ErrorBox message={error} /> : null}
                {success ? <SuccessBox message={success} /> : null}
                <Button
                    isSubmit
                    styleClass={ButtonColors.WHITE_TRANSPARENT}
                    text={notConfirmed ? t('login_page.send_confirm_email') : t('login_page.signin')}
                    onClick={() => {}}
                />
                <ReCAPTCHA
                    size="invisible"
                    theme="dark"
                    sitekey={process.env.REACT_APP_RECAPTCHA_ID as string}
                    ref={recaptchaRef}
                />
            </form>
            <LoadingModal isVisible={loading} />
        </>
    );
};

export default LoginForm;
