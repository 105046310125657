import { FC, useCallback, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { User, WatchedType } from '../../models/user';
import Toast from '../Toast';
import { getPopularSuggestions } from '../../api/mixed-contents';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import LoadingModal from './LoadingModal';
import { editUser } from '../../api/users-requests';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/actions/user';
import { ButtonColors } from '../../utilities/enum/buttonColors';
import { isMobile } from 'react-device-detect';
import { addToWatchedBulk } from '../../api/user-contents-requests';

interface Props {
    isVisible: boolean;
    user: User | null;
}

const SuggestionModal: FC<Props> = ({ isVisible, user }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [contents, setContents] = useState<WatchedType[]>([]);
    const [selectedContents, setSelectedContents] = useState<WatchedType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const fetchContents = useCallback(async () => {
        setIsLoading(true);
        try {
            const fetchedContents = await getPopularSuggestions();
            setContents([
                ...contents,
                ...fetchedContents
                    .filter(
                        (content) =>
                            contents.find((existContent) => existContent.idContent == content.idContent) == undefined,
                    )
                    .map((content) => {
                        return {
                            ...content,
                            idUser: user?.id ?? -1,
                        };
                    }),
            ]);
        } catch (error) {
            setError(t('errors.error_fetching_suggestions'));
        }
        setIsLoading(false);
    }, [contents]);

    const handleCloseToast = useCallback(() => {
        setError('');
    }, []);

    const handleSelectContent = useCallback(
        (selectedContent: WatchedType) => {
            const foundInSelect = selectedContents.find((content) => content.idContent == selectedContent.idContent);
            if (foundInSelect) {
                setSelectedContents(
                    selectedContents.filter((content) => content.idContent != selectedContent.idContent),
                );
            } else {
                setSelectedContents([...selectedContents, selectedContent]);
            }
        },
        [selectedContents],
    );

    const cancelAction = useCallback(async () => {
        if (user) {
            setIsLoading(true);
            try {
                await editUser(user.id, { isFirstLogin: false });
                dispatch(setUser({ ...user, isFirstLogin: false }));
            } catch (error) {
                setError(t('errors.error_updating_user'));
            }
            setIsLoading(false);
        }
    }, [user]);

    const updateSelectedContents = useCallback(async () => {
        if (user && selectedContents.length > 0) {
            setIsLoading(true);
            try {
                await addToWatchedBulk(selectedContents);
                await cancelAction();
            } catch (error) {
                setError(t('error_setting_watched_suggestions'));
            }
            setIsLoading(false);
        }
    }, [user, selectedContents]);

    useEffect(() => {
        if (isVisible) {
            fetchContents();
        }
    }, [isVisible]);

    return (
        <Modal open={isVisible} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div className="modalBox -suggestions">
                <h2 className="modalBox-title">{t('general.suggestion_title')}</h2>
                <div className="modalBox-subtitle">{t('general.suggestion_subtitle')}</div>
                <div className="modalBox-containerList">
                    <div className="modalBox-containerListGrid">
                        {contents.map((content) => {
                            const isSelected = selectedContents.find(
                                (selectedContent) => selectedContent.idContent == content.idContent,
                            );
                            return (
                                <div
                                    className={`modalBox-containerListGridContent ${isSelected ? '-selected' : ''}`}
                                    onClick={() => handleSelectContent(content)}
                                >
                                    <p className="modalBox-containerListGridContentTitle">{content.title}</p>
                                    <img
                                        className="modalBox-containerListGridContentCover"
                                        src={content.img}
                                        width="100%"
                                        loading="lazy"
                                        alt={content.title}
                                    />
                                    {isSelected && (
                                        <div className="modalBox-containerListGridContentSelected">
                                            <Icon name="accept" color="white" size={18} />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <Button
                        styleClass={ButtonColors.LINK_WHITE}
                        onClick={fetchContents}
                        text={t('general.suggestion_load_more')}
                    />
                </div>

                <div className="modalBox-buttonsRow">
                    <Button
                        styleClass={ButtonColors.LINK_OPACITY}
                        onClick={cancelAction}
                        text={t('general.suggestion_cancel')}
                    />
                    <Button
                        styleClass={ButtonColors.WHITE_TRANSPARENT}
                        style={{ width: isMobile ? '100%' : 'auto' }}
                        onClick={updateSelectedContents}
                        disabled={selectedContents.length == 0}
                        text={t('general.suggestion_submit')}
                    />
                </div>
                <Toast message={error} onClose={handleCloseToast} type={'error'} />
                <LoadingModal isVisible={isLoading} />
            </div>
        </Modal>
    );
};

export default SuggestionModal;
