import axios, { AxiosError } from 'axios';
import { baseUrlPlatform } from '../resources/constants';
import { EditDataUser, ErrorType, ResultType, SettingsUser, SettingsUserComplete, User } from '../models/user';
import { LoginData } from '../pages/LoginPage/Phases/LoginPhase';
import { RegisterData } from '../pages/LoginPage/Phases/RegisterPhase';
import { ResetPassword } from '../pages/ResetPasswordPage/ResetPasswordPage';
import { SocialLoginPlatform } from '../utilities/enum/socialLogin';
import QueryString from 'qs';

const setHeaderRecaptcha = (token: string) => {
    return { headers: { 'Recaptcha-Token': token } };
};

export const sendResetPasswordEmail = (email: string, token: string): Promise<ResultType> => {
    return new Promise((resolve, reject) => {
        const headers = setHeaderRecaptcha(token);
        axios
            .post(`${baseUrlPlatform}users/send-reset-password`, { email: email }, headers)
            .then((response) => {
                if (response.data.status === 'ok') {
                    resolve(response.data);
                } else {
                    reject({ error: response.data });
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                if (e.response?.data.error) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};

export const loginUser = (data: LoginData, token: string): Promise<User> => {
    return new Promise((resolve, reject) => {
        const headers = setHeaderRecaptcha(token);

        axios
            .post(`${baseUrlPlatform}users/login`, { ...data }, headers)
            .then((response) => {
                if (!response.data.error) {
                    resolve(response.data);
                } else {
                    reject(response.data as ErrorType);
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e as ErrorType);
            });
    });
};

export const registerUser = (
    data: RegisterData,
    token: string,
): Promise<{ user: string; settings: SettingsUserComplete }> => {
    return new Promise((resolve, reject) => {
        const headers = setHeaderRecaptcha(token);

        axios
            .post(`${baseUrlPlatform}users/create`, { ...data }, headers)
            .then((response) => {
                if (response.data.user) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e as ErrorType);
            });
    });
};
export interface SocialLoginData {
    email: string;
    name: string;
    platform: SocialLoginPlatform;
}
export const socialLogin = (data: SocialLoginData): Promise<User> => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${baseUrlPlatform}users/social-login`, { ...data })
            .then((response) => {
                if (!response.data.error) {
                    resolve(response.data);
                } else {
                    reject(response.data as ErrorType);
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e as ErrorType);
            });
    });
};

export const resetPassowrd = (data: ResetPassword, token: string): Promise<ResultType> => {
    return new Promise((resolve, reject) => {
        const headers = setHeaderRecaptcha(token);

        axios
            .put(`${baseUrlPlatform}users/reset-password`, { ...data }, headers)
            .then((response) => {
                if (response.data.status === 'ok') {
                    resolve(response.data);
                } else {
                    if (response.status === 204) {
                        reject('reset token not found');
                    } else {
                        reject(response.data);
                    }
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e as ErrorType);
            });
    });
};

export const editUser = (uid: number, data: EditDataUser): Promise<User> => {
    return new Promise((resolve, reject) => {
        axios
            .put(`${baseUrlPlatform}users/edit/${uid}`, { ...data })
            .then((response) => {
                if (response.data.error) {
                    reject(response.data);
                }
                resolve(response.data);
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e as ErrorType);
            });
    });
};

export const removeUser = (uid: number): Promise<ResultType> => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${baseUrlPlatform}users/remove/${uid}`)
            .then((response) => {
                if (response.data.error) {
                    reject(response.data);
                }
                resolve(response.data);
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e as ErrorType);
            });
    });
};

export const editUserSettings = (settingId: number, data: SettingsUser): Promise<SettingsUserComplete> => {
    return new Promise((resolve, reject) => {
        axios
            .put(`${baseUrlPlatform}user_settings/edit/${settingId}`, { ...data })
            .then((response) => {
                if (response.data.id) {
                    resolve(response.data);
                }
                reject(response.data);
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e as ErrorType);
            });
    });
};

export const sendConfirmEmail = (email: string): Promise<ResultType> => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${baseUrlPlatform}users/resend-confirm`, { email })
            .then((response) => {
                if (response.data.result === 'ok') {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e as ErrorType);
            });
    });
};

export const confirmAccount = (token: string): Promise<User> => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${baseUrlPlatform}users/confirm-account`, { token })
            .then((response) => {
                if (response.data.id) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e as ErrorType);
            });
    });
};

export const verifyUser = (userId: number): Promise<User> => {
    return new Promise((resolve, reject) => {
        try {
            axios
                .get(`${baseUrlPlatform}users/verify-account/${userId}`)
                .then((response) => {
                    if (response.data.error) {
                        throw response.data;
                    }
                    resolve(response.data);
                })
                .catch((e) => {
                    if (e instanceof AxiosError) {
                        reject(e.response?.data);
                    }
                    reject(e as ErrorType);
                });
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });
};

export const checkEmailExistance = (email: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}users/check`, {
                params: { email },
                paramsSerializer: (params) => QueryString.stringify(params, { encode: false }),
            })
            .then((response) => {
                if (response.data.found) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                if (e.response?.data.error) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};
